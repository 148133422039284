import ContractOracle from './endlessways-common-js/ContractOracle';
import BigNumber from 'bignumber.js';
import axios from 'axios';
export async function fetchTzktProfile(tezosAddress: string): Promise<any | undefined> {
    let tzktProfileUrl = `https://api.tzkt.io/v1/accounts/${tezosAddress}`;
    let settings = { method: "GET" };
    return fetch(tzktProfileUrl, settings)
        .then(res => {
            return res.json();
        })
        .then((json) => {
            //console.log("fetch of",tzktProfileUrl,"returned",json);
            return json;
        })
        .catch((error) => {
            console.error(error);
            return undefined;
        });
}

export async function fetchTokenOwnerFromTzkt(tokenId: BigNumber, contractOracle: ContractOracle): Promise<any | undefined> {
    const artworkId = ContractOracle.getArtworkIdForTokenId(tokenId);
    const searchParams = new URLSearchParams();
    const contractAddress = (await contractOracle.getContractDirectAccess(artworkId))?.contractAddress;
    if (!contractAddress) {
        return undefined;
    }
    searchParams.append("token.contract", contractAddress);
    searchParams.append("token.tokenId", tokenId.toString());
    searchParams.append("select", "account");
    const url = `https://api.${contractOracle.tzktNetworkName}.tzkt.io/v1/tokens/balances?` + searchParams.toString();
    //console.log(url);
    const tokenOwnerResult = await axios.get(url);
    //console.log("for token", tokenId, "got owner", tokenOwnerResult.data);
    return tokenOwnerResult.data[0];
}


export default fetchTzktProfile;
