import { BeaconWallet } from "@taquito/beacon-wallet";
import { useEffect, useState } from 'react';
import ArtworkInfo from '../engine/endlessways-common-js/ArtworkInfo';

import {
    Route,
    Switch,
    useParams,
    useRouteMatch
} from "react-router-dom";
import EditArtwork from "./EditArtwork";
import { Mint } from "./Mint";
import ArtworkGallery from "./ArtworkGallery";
import BigNumber from 'bignumber.js';
//import useTraceUpdate from '../engine/TraceUpdate';
import ContractOracle from '../engine/endlessways-common-js/ContractOracle';
import FourOhFour from './FourOhFour';
import ArtworkList from "./ArtworkList";

type ArtworksProps = {
    contractOracle: ContractOracle,
    wallet?: BeaconWallet,
    forAdminPage: boolean,
    showArtistAddress?: boolean,
    routeMatchUrl?: string,
    maxCount?: number
}

function Artworks(props: ArtworksProps) {

    //useTraceUpdate("Artworks", props);
    
    async function refreshMintCount(artworkId: number): Promise<ArtworkInfo | undefined> {
        //console.log("Artworks calling contractOracle.refreshMintCount...");
        const refreshedArtwork = await props.contractOracle.refreshMintCount(artworkId);
        return refreshedArtwork;
    }

    const listRoutePath = props.forAdminPage ? "/admin" : "/artworks";

    return (
        <Switch>
            <Route exact path={`/artworks/:artworkIdAsString(\\d+)/edit`}>
                <EditArtwork contractOracle={props.contractOracle} wallet={props.wallet} />
            </Route>
            <Route path={`/artworks/:artworkIdAsString(\\d+)/:previewSeed([0-9a-f]{64})?`}>
                <ArtworkWrapper contractOracle={props.contractOracle} wallet={props.wallet} refreshMintCountCallback={refreshMintCount}/>
            </Route>
            <Route exact={true} path={listRoutePath}>
                <h1>Artworks</h1>
                <ArtworkList contractOracle={props.contractOracle} wallet={props.wallet} forAdminPage={props.forAdminPage} showArtistAddress={props.showArtistAddress}/>
            </Route>
            <Route path="*">
                <FourOhFour />
            </Route>
        </Switch>
    )
}


type ArtworkWrapperProps = {
    contractOracle: ContractOracle
    wallet?: BeaconWallet
    refreshMintCountCallback: (artworkId: number) => Promise<ArtworkInfo | undefined>
}

function ArtworkWrapper(props: ArtworkWrapperProps) {

    //useTraceUpdate(props);

    let { artworkIdAsString } = useParams<{ artworkIdAsString: string }>();
    let artworkId = parseInt(artworkIdAsString);
    //console.log("got artwork id " + artworkId + " -> " + parseInt(artworkId) + ", artworks is " + props.artworks);

    //useTraceUpdate("ArtworkWrapper", props);

    const routeMatch = useRouteMatch()
    //console.log("ArtworkWrapper - routeMatch is", routeMatch);

    const [artwork, setArtwork] = useState<ArtworkInfo>();

    useEffect(() => {
        (async () => {
            //console.log("fetching artwork");
            const artwork = await props.contractOracle.getArtwork(artworkId);
            if (artwork) {
                //console.log("fetched",artwork,"with mint count",artwork.mint_count.toString());
                setArtwork(artwork);
            }
        })();
    }, [artworkId, props.contractOracle]);

    const refreshMintCountCallback = async () => {
        const artworkInfo = await props.refreshMintCountCallback(artworkId);
        if (artworkInfo) {
            //console.log("got newMintCount", artworkInfo.mint_count.toString());
            setArtwork(artworkInfo);
        } else {
            //console.warn("newMintCount was undefined");
        }
        return artworkInfo;
    }
    
    return (
        artwork ? (
            <div>
                <Route path={`${routeMatch.path}/:mintNumber(\\d+)`} render={({ match }) => {
                    const mintNumber = new BigNumber(match.params.mintNumber);
                    return <Mint artwork={artwork} artworkId={artworkId} mintNumber={mintNumber.toString()} contractOracle={props.contractOracle} live={true} />
                }} />
                <Route exact path={`${routeMatch.path}`}>
                    <ArtworkGallery contractOracle={props.contractOracle} wallet={props.wallet} artwork={artwork} refreshMintCountCallback={refreshMintCountCallback}/>
                </Route>
            </div>

        ) : (<h1 className="fade-in-out-2s">Loading artwork #{artworkId} from contract...</h1>)
    )
}

export default Artworks;
