import { useEffect, FunctionComponent, useRef } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
 function useOutsideAlerter(ref: any, callback: () => void) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */

type OutsideAlerterProps = {
    callback: () => void
}

export const OutsideAlerter: FunctionComponent<OutsideAlerterProps> = ({callback, children}) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, callback);

    return <div ref={wrapperRef}>{children}</div>;
}

export default OutsideAlerter;
