import { FunctionComponent, useEffect, useState } from 'react';
import ArtworkInfo from '../engine/endlessways-common-js/ArtworkInfo';
import BigNumber from 'bignumber.js';
import ContractOracle from '../engine/endlessways-common-js/ContractOracle';
import { MintHtmlGenerator } from '../engine/endlessways-common-js/MintHtmlGenerator';
import ErrorBoundary from './ErrorBoundary';
import MintThumbnail from './MintThumbnail';
import { Link } from 'react-router-dom';
import AddressOrTzktAlias from './AddressOrTzktAlias';
import Avatar from './Avatar';
import HoverImage from './HoverImage';
import Frame from 'react-frame-component';
import { fetchTokenOwnerFromTzkt } from '../engine/TZKTProfile';

export enum MintDisplayConfiguration {
    PrioritiseMintNumberAndShowOwner,
    PrioritiseTitleAndShowArtist
}

interface MintProps {
    contractOracle: ContractOracle
    artworkId: number,
    artwork?: ArtworkInfo,
    mintNumber: string,
    live: boolean,
    overrideSeed?: string,
    rerollCount?: number,
    configuration?: MintDisplayConfiguration
}

export const Mint: FunctionComponent<MintProps> = (props) => {
    //mint', props.mintNumber.toString());

    //console.log("Rendering mint",props.mintNumber.toString(),"with props.artwork", props.artwork);

    //const [tokenMetadata, setTokenMetadata] = useState<any>();
    const [tokenNotFound, setTokenNotFound] = useState(false);
    const [tokenSeed, setTokenSeed] = useState<any>();
    const [tokenOwner, setTokenOwner] = useState<{address: string, alias?: string}>();
    const tokenId = ContractOracle.getTokenIdForMint(props.artworkId, new BigNumber(props.mintNumber));
    const configuration = props.configuration ?? MintDisplayConfiguration.PrioritiseMintNumberAndShowOwner;
    //useTraceUpdate("Mint", props);

    //const prevContract = useRef(props.contractOracle.getContractDirectAccess(props.artworkId)?.contract);
    //const prevTokenId = useRef(tokenId);

    const [artwork, setArtwork] = useState<ArtworkInfo | undefined>(props.artwork);
    useEffect(() => {
        (async () => {
            if (!props.artwork) {
                //console.log("Mint fetching artwork");
                const artwork = await props.contractOracle.getArtwork(props.artworkId);
                //console.log("Mint fetched artwork", artwork);
                setArtwork(artwork);
            }
        })()
    }, [props.artwork, props.artworkId, props.contractOracle]);

    useEffect(() => {
        async function getTokenSeed() {
            const mintNumber = new BigNumber(props.mintNumber);
            if (props.overrideSeed) {
                setTokenSeed(undefined);
            } else if (mintNumber.lt(1) || (artwork && mintNumber.gt(artwork.max_mint_count))) {
                setTokenNotFound(true);
            } else {
                try {
                    // for token id", tokenId.toString());
                    const seed = await props.contractOracle.getTokenSeed(tokenId, {forceRefresh: (props.rerollCount ?? 0) > 0});
                    if (!seed) {
                        console.error("no seed for token " + tokenId);
                        setTokenNotFound(true);
                    } else {
                        //console.log('fetched token seed ', seed);
                        setTokenSeed(seed);
                    }
                } catch (err) {
                    console.error("error getting seed:", err);
                }
            }
        }
        if (props.live) {
            getTokenSeed();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.live, props.mintNumber, props.rerollCount, props.overrideSeed]);

    //console.log('token metadata as JSON:');
    //console.log(tokenMetadata);
    const liveUri = "/live/" + tokenId.toString();
    const artworkUri = "/artworks/" + artwork?.id;
    const displayImageUri = props.contractOracle.getDisplayImageUri(tokenId);
    const contractAddress = props.contractOracle.getActiveContractDirectAccess()?.contractAddress;
    const marketplaceUri = `https://objkt.com/asset/${contractAddress}/${tokenId.toString()}`;

    //const thumbnailUri = "/thumbnail/" + tokenId.toString();
    //const thumbnailUri = "/" + tokenId.toString();

    const [p5jsPage, setP5jsPage] = useState<string>("");
    useEffect(() => {
        if (!artwork || !props.live || !(tokenSeed || props.overrideSeed)) {
            //console.log("p5js build useEffect called with artwork", artwork, "but gave up");
            return undefined;
        }
        const script = artwork.script
        const artworkId = artwork.id;
        const scriptType = MintHtmlGenerator.getScriptTypeForString(artwork.scriptTypeString);
        const seed = props.overrideSeed ?? tokenSeed;
        //console.log("built p5js page with seed", seed, "script", artwork.script);
        setP5jsPage(MintHtmlGenerator.getMintHtml(scriptType, artworkId, script, seed, new BigNumber(props.mintNumber)));
    }, [artwork, props.mintNumber, tokenSeed, props.overrideSeed, props.live])

    /*if (artwork) { 
        console.log("rendering mint with artwork", props.artworkId, "page content is " + (p5jsPage ? "defined" : "undefined"));
    } else {
        console.log("rendering mint with undefined artwork");
    };*/

    useEffect(() => {
        if (artwork && (props.configuration !== MintDisplayConfiguration.PrioritiseTitleAndShowArtist)) {
            (async () => {
                const tokenOwners = artwork?.token_owners;
                if (tokenOwners) {
                    setTokenOwner({address: tokenOwners[tokenId.toString()] ?? "unknown"});
                } else {
                    // get from tzkt
                    const fetchedTokenOwner = await fetchTokenOwnerFromTzkt(tokenId, props.contractOracle);
                    setTokenOwner(fetchedTokenOwner ?? { address: "", alias: "unknown" });
                }
            })()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [artwork]);


    return ( 
        (tokenNotFound) ? ( 
            <div className="token-not-found">no mint #{props.mintNumber.toString()} yet</div>
        ) : (
            artwork ? (
                props.live ? (
                    (p5jsPage) ? (
                        <div className="mint-live" style={{"aspectRatio": `${artwork.aspectRatio}`}}>
                            <ErrorBoundary data={p5jsPage}>
                                <Frame style={{ display: "block", width: "100%", height: "100%", aspectRatio: `${artwork.aspectRatio}` }} initialContent={p5jsPage} frameBorder={0} >
                                </Frame>
                            </ErrorBoundary>
                        </div>
                    ) : ( <span /> )
                ) : ( 
                    <div className="mint-static">
                        <a href={liveUri} >
                            <MintThumbnail artwork={artwork} mintNumber={new BigNumber(props.mintNumber)} contractOracle={props.contractOracle} doGradientOverlay={false} />
                        </a>
                        <div className="details" >
                            <a href={artworkUri} >
                                {(configuration === MintDisplayConfiguration.PrioritiseMintNumberAndShowOwner) ? <>
                                    <div className="mint-number">Mint #{props.mintNumber.toString()}</div>
                                    <div className="title">{artwork.title}</div>
                                </> : <>
                                    <div className="mint-number">{artwork.title}</div>
                                    <div className="title">Mint #{props.mintNumber.toString()}</div>
                                </>}
                            </a>
                            {(configuration === MintDisplayConfiguration.PrioritiseMintNumberAndShowOwner) ? 
                                <Link to={`/collection/${tokenOwner?.address}`}>
                                    { tokenOwner && <Avatar address={tokenOwner.address} /> }
                                    <div className="owner">{tokenOwner && (
                                        tokenOwner.alias ?? <AddressOrTzktAlias address={tokenOwner.address}/>)}</div>
                                </Link>
                                :
                                <Link to={`/artworks/${artwork.id}`}>
                                    <Avatar address={artwork.artist_address} />
                                    <div className="owner"><AddressOrTzktAlias address={artwork.artist_address}/></div>

                                </Link>
                            }
                        </div>
                        <div className="buttons">
                            <a href={liveUri}>
                                <HoverImage staticUrl="/images/liveButton.svg" hoverUrl="/images/liveButton-over.svg" style={{width: "22px", height: "22px"}} altText="live icon" />
                            </a>
                            <a href={displayImageUri}>
                                <HoverImage staticUrl="/images/pngButton.svg" hoverUrl="/images/pngButton-over.svg" style={{width: "22px", height: "22px"}} altText="PNG icon" />
                            </a>
                            <a href={marketplaceUri}>
                                <HoverImage staticUrl="/images/externalLinkButton.svg" hoverUrl="/images/externalLinkButton-over.svg" style={{width: "22px", height: "22px"}} altText="external link icon" />
                            </a>                                    
                        </div>
                    </div> 
                )
            ) : ( <h2>loading...</h2> )
        )
    )
}

export default Mint;