import { FunctionComponent } from 'react';

type CurationProps = {

}

export const Curation: FunctionComponent<CurationProps> = (props) => {


    return (
        <div className="body-text">
            <h1>Curation</h1>
            <p>Artworks on Endless Ways are released in curation collections called Exhibitions. We have written extensively about our curation principles here: <a href="https://docs.google.com/document/d/10X7dHiI8Y89UfHGBCUn9bXn2aSBUfSPPWXMDN4OC48I">Endless Ways Curation Principles</a>. We welcome feedback - please feel free to discuss what we're doing with us on <a href="https://discord.gg/AQn8E5ddvS">our Discord</a>.</p>
            <h2>Why curation?</h2>
            <p>Curation helps audiences to better understand an artwork. When artworks with related themes or styles are shown together, audiences can more easily understand how the works are related, and how they differ. Rather than standing alone, or being swallowed by a sea of content, a curated artwork is connected to its context in a clear and highly visible way.</p>
            <p>Curation helps artists connect with audiences. Rather than being one in a flat market of thousands, an artist in a curated collection becomes one of a handful of related, connected artists. Networks, access, and fortunes are bound together, and shared to a lesser or greater degree.</p>
            <p><span className="important">We see curation as a tool to make art more accessible. If it feels like gatekeeping, then it's not being done right.</span> That is why we do not want to establish ourselves as the arbitrators of quality. We do not curate the works ourselves - instead, <span>we curate the curators</span>.</p>
            <h2>How does curation on Endless Ways work?</h2>
            <p>On Endless Ways, anyone with a concrete concept can apply to be a curator of an exhbition. Our acceptance criteria are simple: can we understand what you want to do? If yes, then we pledge to publish your exhibition on Endless Ways.</p>
            <p>In practise, we envisage that the curation process on Endless Ways might look something like this: <a href="https://docs.google.com/document/d/1NsrfWaBIn8nYkuf-vPmYsDuZ7fxwXziwcR5N2fX-eMo">The Curation Process on Endless Ways - Examples</a>.</p>
        </div>
    )


}

export default Curation;