import ContractOracle from '../engine/endlessways-common-js/ContractOracle';
import { useEffect } from "react";
import { BeaconWallet } from '@taquito/beacon-wallet';
import ArtworkList from './ArtworkList';

type HomeProps = {
    contractOracle: ContractOracle
    wallet?: BeaconWallet
};

function Home(props: HomeProps) {
    useEffect(() => {
        document.title = `Endless Ways`;
    }, []);

    return (
        <div className="home">
            <h1>Generative art. Proper curation.</h1>
            <div className="body-text">
                <p>Endless Ways is an NFT platform showcasing on-chain generative artwork on Tezos.</p>
                <p>Artworks are released in curated collections. Curation on Endless Ways is about enabling and spreading: by
                    grouping related works together, shared qualities are easier to see, and individual pieces are made
                    easier to understand as part of an ongoing conversation across artistic communities.</p>
                <p>Much thought and care has gone into making Endless Ways a reality. We hope that you enjoy being here as much as we've enjoyed putting it together.</p>
            </div>
            <ArtworkList contractOracle={props.contractOracle} wallet={props.wallet} forAdminPage={false}/>
        </div>
    )
}

export default Home;
