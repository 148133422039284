import { compose, MichelsonMap, TezosToolkit, WalletContract } from '@taquito/taquito';
import { tzip12 } from '@taquito/tzip12';
import { tzip16 } from '@taquito/tzip16';
import ArtworkInfo from './ArtworkInfo';
import BigNumber from 'bignumber.js';
import ContractOracle from './ContractOracle';

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function range(startAt: number, count: number): ReadonlyArray<number> {
    return [...Array(count).keys()].map(i => i + startAt);
}

function arrayAny(arr: readonly any[], predicate: (element: any) => Boolean): Boolean {
    if (arr.length === 0) {
        return false;
    }
    //let foundIndex = arr.findIndex(predicate);
    //console.log("arrayAny found index", foundIndex);
    return arr.findIndex(predicate) !== -1
}


export type Config = {
    tezosToolkit: TezosToolkit
    contractAddress: string
    firstArtworkId: number
    lastArtworkId: number | undefined
    separateScriptAndMintCount: boolean
}

export class ContractDirectAccess {

    tezosToolkit: TezosToolkit
    contractAddress: string
    firstArtworkId: number
    lastArtworkId: number | undefined
    separateScriptAndMintCount: boolean

    contract?: WalletContract;
    storage?: any;

    cachedArtworks: Map<number, ArtworkInfo>;

    isSetup: boolean = false;
    isSettingUp: boolean = false;

    constructor(config: Config) {
        //console.log("ContractOracle config:", config);
        this.tezosToolkit = config.tezosToolkit;
        this.contractAddress = config.contractAddress;
        this.firstArtworkId = config.firstArtworkId;
        this.lastArtworkId = config.lastArtworkId;
        this.separateScriptAndMintCount = config.separateScriptAndMintCount

        this.cachedArtworks = new Map<number, ArtworkInfo>();
    }

    async setup(): Promise<ContractDirectAccess | undefined> {
        if (this.isSetup) {
            return this;
        }
        if (this.isSettingUp) {
            const waitForSetup = (resolve: any, reject: any) => {
                if (!this.isSettingUp) {
                    resolve(this);
                } else {
                    setTimeout(waitForSetup.bind(this, resolve, reject), 100);
                }
            }
            return new Promise(waitForSetup);
        } else {
            this.isSettingUp = true;
            try {
                //console.log("ContractDirectAccess.setup() loading contract for " + this.contractAddress);
                const walletContract = await this.tezosToolkit.wallet.at(this.contractAddress, compose(tzip12, tzip16));
                //console.log("ContractDirectAccess.setup() got contract for address " + this.contractAddress + ":", walletContract);
                this.contract = walletContract;
                this.updateAndGetStorage();
                this.isSetup = true;
                return this;
            } catch (err) {
                console.error("Unable to setup ContractDirectAccess", err);
            } finally {
                this.isSettingUp = false;
            }
            return undefined;
        }
    }

    async getStorage(): Promise<any> {
        if (!this.isSetup) {
            await this.setup();
        }
        while (!this.storage) {
            await sleep(500);
        }
        return this.storage;
    }

    async updateAndGetStorage(): Promise<any> {
        while (!this.contract) {
            await sleep(500);
        }
        this.storage = await this.contract.storage();
        return this.storage;
    }

    async getBaseImagesUri(): Promise<string> {
        const storage = await this.getStorage();
        return await storage.metadata.get("base_images_uri");
    }

    async getArtworkCount(): Promise<number> {
        if (this.lastArtworkId) {
            return (this.lastArtworkId - this.firstArtworkId) + 1;
        }
        let storage = await this.updateAndGetStorage();
        return storage.next_artwork_id.minus(storage.first_artwork_id).toNumber()
    }

    async getAdminWalletAddress(): Promise<String> {
        let storage = await this.getStorage();
        return storage.administrator;
    }

    async getArchivistWalletAddress(): Promise<String> {
        let storage = await this.getStorage();
        return storage.archivist;
    }

    async getTokenSeed(tokenId: BigNumber): Promise<string | undefined> {
        const storage = await this.getStorage();
        return await storage.token_seeds.get(tokenId);
    }

    async getArtworks(startArtworkIdIn: number, countIn: number, forceRefresh: boolean = false): Promise<ArtworkInfo[]> {
        //console.log(`getting artworks ${startArtworkIdIn}...${startArtworkIdIn+countIn-1} from ${this.contractAddress}`);
        if (!this.isSetup) {
            await this.setup();
        }

        const storage = await (forceRefresh ? this.updateAndGetStorage() : this.getStorage());
        const storageFirstArtworkId = storage.first_artwork_id;
        const storageLastArtworkId = storage.next_artwork_id - 1;
        if (startArtworkIdIn > storageLastArtworkId) {
            //console.log("start",startArtworkIdIn,"> storageLast",storageLastArtworkId);
            return [];
        }
        const startArtworkId = Math.max(storageFirstArtworkId, startArtworkIdIn);
        const endArtworkId = Math.min(storageLastArtworkId, startArtworkIdIn + countIn - 1);
        if (endArtworkId < storageFirstArtworkId) {
            //console.log("end",endArtworkId,"< storagefirst",storageFirstArtworkId);
            return [];
        }
        //console.log(`-> actual list: ${startArtworkId}...${endArtworkId}`);
        const count = (endArtworkId - startArtworkId) + 1;
        if (count === 0) {
            return [];
        }
        const artworkIds = range(startArtworkId, count);
        //console.log("checking artwork ids", artworkIds);
        var cachedArtworks = /*this.cachedArtworks*/new Map<number, ArtworkInfo>();
        const needsFetch = forceRefresh || arrayAny(artworkIds, (artworkId: number) => {
            const exists = cachedArtworks.has(artworkId);
            //console.log(`artwork id ${artworkId} exists: ${exists}`);
            return !exists;
        })
        if (needsFetch) {
            //console.log(`fetching:`, artworkIds);
            const artworks: MichelsonMap<number, ArtworkInfo> = await storage.artworks.getMultipleValues(artworkIds);

            // for contracts with separate script and mint count
            var mintCounts: MichelsonMap<number, BigNumber> | undefined;
            var scripts: MichelsonMap<number, string> | undefined;
            if (this.separateScriptAndMintCount) {
                mintCounts = await storage.artwork_mint_count.getMultipleValues(artworkIds);
                scripts = await storage.artwork_script.getMultipleValues(artworkIds);
            }
            //console.log("got scripts", scripts);
            for (var i=0; i<artworkIds.length; i++) {
                const artworkId = artworkIds[i];
                var info = artworks.get(artworkId);
                if (!info) {
                    continue;
                }
                //console.log("pre: artwork",artworkId,"with raw data", info);

                if (this.separateScriptAndMintCount) {
                    info.mint_count = mintCounts!.get(artworkId) ?? new BigNumber(0);
                    info.script = scripts!.get(artworkId) ?? "";
                    //console.log("assigned script", info.script, "for artworkid", artworkId);
                } 

                // this is too slow
                //const tokenIds = this.buildTokenIdList(artworkId, info.mint_count);
                //const tokenOwners = await storage.ledger.getMultipleValues(tokenIds);
                //console.log("for artwork", artworkId, "got token owners", tokenOwners);

                info.id = artworkId;
                //console.log("got artwork",artworkId,"with script", info.script);
                // store 
                //console.log("assigning info for",artworkId,"(different:",this.cachedArtworks.get(artworkId) !== info,")");
                cachedArtworks.delete(artworkId)
                cachedArtworks.set(artworkId, info);
            };



            //console.log("fetched, artworks:", cachedArtworks);
        } else {
            //console.log("No fetch needed");
        }
        var result = new Array<ArtworkInfo>();
        for (let artworkId of artworkIds) {
            const info = cachedArtworks.get(artworkId);
            if (info) {
                //console.log("getArtworks pushing to result array artwork with script ", info.script, "for artwork id", artworkId);
                result.push(info);
            } else {
                //console.log("no info for ", artworkId, "; have:", this.cachedArtworks.keys());
            }
        }
        //console.log("assigned this.cachedArtworks:", cachedArtworks);
        this.cachedArtworks = cachedArtworks;
        //console.log("CDA getArtworks got artworks:", result);
        return result;
    }


    async refreshMintCount(artworkId: number): Promise<ArtworkInfo | undefined> {
        var artwork = this.cachedArtworks.get(artworkId);
        if (!artwork) {
            return (await this.getArtworks(artworkId, 1, true))[0];
        }
        let updatedStorage = await this.getStorage();
        /*
        let updatedArtwork: ArtworkInfo = await updatedStorage.artworks.get(artworkId);
        const newMintCount = updatedArtwork.mint_count;*/
        const newMintCount = updatedStorage.artwork_mint_count.get(artworkId);

        //console.log("update artwork has mint count",updatedArtwork.mint_count,"existing has",artwork.mint_count);
        if (newMintCount.isEqualTo(artwork.mint_count)) {
            //console.log("no change");
            return artwork;
        }
        var newArtwork = Object.assign({}, artwork);
        newArtwork.mint_count = newMintCount;
        this.cachedArtworks.set(artworkId, newArtwork);
        return newArtwork;
    }

    buildTokenIdList(artworkId: number, mintCount: BigNumber): BigNumber[] {
        var result: BigNumber[] = [];
        for (var mintNumber = new BigNumber(1); mintNumber.lte(mintCount); mintNumber = mintNumber.plus(1)) {
            result.push(ContractOracle.getTokenIdForMint(artworkId, mintNumber));
        }
        return result;
    }

}



export default ContractDirectAccess;
