import BigNumber from 'bignumber.js';

export enum ArtworkScriptType {
    P5JS_1_4_0,
    THREEJS_0_135_0,
    TONEJS_14_7_77,
    CUSTOM
}

export interface ArtworkScriptHeaderFooter {
    header: string;
    footer: string;
}

export class MintHtmlGenerator {

    static kDefaultScriptType = "p5.js v1.4.0";

    static getStringForScriptType(type: ArtworkScriptType): string {
        switch (type) {
            case ArtworkScriptType.P5JS_1_4_0:
                return "p5.js v1.4.0";
            case ArtworkScriptType.THREEJS_0_135_0:
                return "three.js v0.135.0";
            case ArtworkScriptType.TONEJS_14_7_77:
                return "tone.js v14.7.77";
            case ArtworkScriptType.CUSTOM:
                return "custom";
            default:
                throw new Error("Unrecognised script type: " + type);
            }
        }
            
    static getScriptTypeForString(typeString: string): ArtworkScriptType {
        switch (typeString) {
            case "p5.js": 
            case "p5.js v1.4.0":
                    return ArtworkScriptType.P5JS_1_4_0;
            case "three.js v0.132.2":
            case "three.js v0.135.0":
                return ArtworkScriptType.THREEJS_0_135_0;
            case "tone.js v14.7.77":
                return ArtworkScriptType.TONEJS_14_7_77;
            case "custom":
                return ArtworkScriptType.CUSTOM;
            default:
                throw new Error("Unrecognised script type: " + typeString);
        }
    }

    static getMintHtml(artworkScriptType: ArtworkScriptType, artworkId: number, script: string, tokenSeed: string, mintNumber: BigNumber): string {
        const defaultScript =  `
        function setup() {
            print('no code');
            frameRate(1);
            colorMode(HSB);
            background(color(random(360),random(60,200),random(128,255)));
        }
        
        function draw() {
            background(color(random(360),random(30,64),255));
            print('no code, draw frame ' + frameCount);
        }`

        const artworkSource = (script.length > 0) ? script : defaultScript;
        const tokenInfoJS = this.getTokenJS(artworkId, mintNumber, tokenSeed);

        const template = this.getTemplate(artworkScriptType);
        const templatePlusSource = this.injectArtworkSource(template, artworkSource);
        //console.log("templatePlusSource:", templatePlusSource);
        const complete = this.injectEndlessWaysTokenInfoJS(templatePlusSource, tokenInfoJS);
        //console.log("complete:", templatePlusSource);
        return complete;

    }

    static getTokenJS(artworkId: number, mintNumber: BigNumber, tokenSeed: string) {
        return `const endlessWaysTokenInfo = {
    artworkId: ${artworkId}, 
    mintNumber: "${mintNumber.toString()}",
    seed: "${tokenSeed}"
};`
    }

    static getTemplate(scriptType: ArtworkScriptType): string {
        switch(scriptType) {
            case ArtworkScriptType.P5JS_1_4_0:
                return `
<html>
    <head>
        <meta charset="utf-8">
        <script language="javascript" type="text/javascript" src="https://cdn.jsdelivr.net/npm/p5@1.4.0/lib/p5.min.js"></script>
        <script language="javascript" type="text/javascript">
        !!!InjectEndlessWaysTokenInfoJSHere!!!
        !!!InjectArtworkSourceHere!!!
        </script>
        <style> body { padding: 0; margin: 0; overflow: hidden; position: absolute; left: 50%; top: 50%; 
            transform: translate(-50%, -50%); } 
            canvas { display: block; }
        </style>    
    </head>
    <body><main></main></body>
</html>
`;
            case ArtworkScriptType.THREEJS_0_135_0:
                return `
<html>
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0">
        <style> body { padding: 0; margin: 0; overflow: hidden; position: absolute; left: 50%; top: 50%; 
            transform: translate(-50%, -50%); } </style>    
    </head>
    <body>
        <div id="container"></div>
        <script language="javascript" type="module">
            !!!InjectEndlessWaysTokenInfoJSHere!!!
            !!!InjectArtworkSourceHere!!!
        </script>
    </body>
</html>
            `;
            case ArtworkScriptType.TONEJS_14_7_77:
                return `<html>
    <head>
        <meta charset="utf-8">
        <script language="javascript" type="text/javascript" src="../EndlessWaysTestHelper.js" ></script>
        <style>
            body { padding: 0; margin: 0; overflow: hidden; position: absolute; left: 50%; top: 50%; 
                transform: translate(-50%, -50%); }
            button { margin: 0 auto; display: block; width: 300; height: 80; }
        </style>
        <script language="javascript" type="module">
            !!!InjectEndlessWaysTokenInfoJSHere!!!
            !!!InjectArtworkSourceHere!!!
        </script>
    </head>
    <body>
        <button id="play-button">Start Audio</button>
        <div id="message"></div>
    </body>
</html>`;
            case ArtworkScriptType.CUSTOM:
                return `!!!InjectArtworkSourceHere!!!`;
            default:
                throw new Error(`ArtworkScriptType ${this.getStringForScriptType(scriptType)} not supported`);
        }
    }

    static getPlaceholderSample(scriptType: ArtworkScriptType): string {
        switch (scriptType) {
            case ArtworkScriptType.P5JS_1_4_0:
                return (
`function setup() {
    createCanvas(windowWidth, windowHeight);
}

function draw() {
    background(255,0,0);
}`);
            case ArtworkScriptType.THREEJS_0_135_0:
                return (
`// use CDN
import * as THREE from 'https://cdn.skypack.dev/three@0.135.0';

// if you need extra imports from the three.js examples library, import them like this:
import { TrackballControls } from 'https://cdn.skypack.dev/three@0.135.0/examples/jsm/controls/TrackballControls.js';

init();
animate();

function init() {

}

function animate() {
    requestAnimationFrame( animate );
    render();
}

function render() {

}
`);
            case ArtworkScriptType.TONEJS_14_7_77:
                return `
// use CDN:
import * as Tone from 'https://cdn.skypack.dev/tone@14.7.77';

// attach a click listener to the play button
document.getElementById('play-button')?.addEventListener('click', async () => {
    await Tone.start()
})

const synth = new Tone.Synth().toDestination();
synth.triggerAttackRelease("C4", "8n");
`
                ;
            case ArtworkScriptType.CUSTOM:
                return (
`<html>
    <head>
        <meta charset="utf-8">
    </head>
    <body onload="writeTokenDetails()">
        <div id="text"></div>
        <script>
            !!!InjectEndlessWaysTokenInfoJSHere!!!
            function writeTokenDetails() {
                const detailText = "Mint #" + endlessWaysTokenInfo.mintNumber + 
                    ", seed: " + endlessWaysTokenInfo.seed;
                document.getElementById('text').innerHTML = detailText;
            }
        </script>
    </body>
</html>`);
            default:
                throw new Error(`ArtworkScriptType ${this.getStringForScriptType(scriptType)} not supported`);
        }
    }


    static injectArtworkSource(into: string, artworkSource: string): string {
        const index = into.indexOf("!!!InjectArtworkSourceHere!!!");
        if (index === -1) {
            throw new Error("Couldn't find artwork source injection tag in input string");
        }
        return into.replace("!!!InjectArtworkSourceHere!!!", artworkSource);
    }

    static injectEndlessWaysTokenInfoJS(into: string, tokenInfoJS: string): string {
        const index = into.indexOf("!!!InjectEndlessWaysTokenInfoJSHere!!!");
        if (index === -1) {
            throw new Error("Couldn't find Endless Ways token info JS injection tag in input string");
        }
        return into.replace("!!!InjectEndlessWaysTokenInfoJSHere!!!", tokenInfoJS);
    }

}

export default MintHtmlGenerator;