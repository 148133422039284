import { FunctionComponent, useState, useEffect } from "react";
import ArtworkInfo from "../engine/endlessways-common-js/ArtworkInfo";
import ContractOracle from "../engine/endlessways-common-js/ContractOracle";
import BigNumber from 'bignumber.js';
import { Grid } from "react-loader-spinner";
//import TreeAnimation from "./TreeAnimation";

interface MintThumbnailProps {
    contractOracle: ContractOracle,
    artwork: ArtworkInfo,
    mintNumber: BigNumber,
    doGradientOverlay: boolean
}

export const MintThumbnail: FunctionComponent<MintThumbnailProps> = (props) => {

    const [thumbnailUri, setThumbnailUri] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() => {
        //console.log("in MintThumbnail", props.mintNumber.toString(), props.artwork.mint_count.toString())
        if (props.mintNumber.gte(1) && props.mintNumber.lte(props.artwork.mint_count)) {
            const tokenId = ContractOracle.getTokenIdForMint(props.artwork.id, props.mintNumber);
            const thumbnailUri = props.contractOracle.getThumbnailUri(tokenId);
            setThumbnailUri(thumbnailUri);
        }
    }, [props.artwork, props.contractOracle, props.mintNumber]);
    //console.log("thumbnail for mint ", props.mintNumber.toString(), "is loading:", isLoading, "from", thumbnailUri);

    return (
        <div className={"mint-thumbnail"} style={{"aspectRatio": `${props.artwork.aspectRatio}`}}>
            {(props.mintNumber.lt(1) || props.mintNumber.gt(props.artwork.mint_count)) ? ( 
                <div className="token-not-found">Mint #{props.mintNumber.toString()} not found</div>
            ) : (
                <>
                    {isLoading && (
                        /*<div className="tree-loader">
                            <TreeAnimation />
                        </div>*/
                        <div className="grid-loader">
                            <Grid color="#999" height="100%" width="100%" />
                        </div>
                    )}
                    <div className={props.doGradientOverlay ? " gradient-overlay" : "wrapper"} >
                        {thumbnailUri && <img 
                            src={thumbnailUri} 
                            onLoadStart={() => setIsLoading(true)}
                            onLoad={() => setIsLoading(false)}
                            alt={`thumbnail of mint ${props.mintNumber.toString()} of ${props.artwork.title} by ${props.artwork.artist}`} 
                        />}
                    </div>
                </>
            )}
        </div>
    )

}

export default MintThumbnail;
