import { FunctionComponent, useEffect, useState } from 'react';
import fetchTzktProfile from '../engine/TZKTProfile';

type AddressOrTzktAliasProps = {
    address: string,
    default?: string,
    prefix?: string
}

export const AddressOrTzktAlias: FunctionComponent<AddressOrTzktAliasProps> = (props) => {

    const truncateAddress = (address: string) => {
        return address.substring(0, 4) + "..." + address.substring(31, 36);
    }

    const [alias, setAlias] = useState<string>(props.default ?? truncateAddress(props.address));


    useEffect(() => {
        (async () => {
            const tzktProfile = await fetchTzktProfile(props.address);
            //console.log("got profile", tzktProfile, "for owner address", props.address);
            if (tzktProfile?.alias) {
                const newAlias = (props.prefix ?? "") + tzktProfile.alias;
                //console.log("setting alias to ", newAlias);
                setAlias(newAlias);
            } 
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.address]);

    return <>{alias}</>
}

export default AddressOrTzktAlias