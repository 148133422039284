import { FunctionComponent } from "react";
//import { useRouteMatch } from 'react-router';

interface FourOhFourProps {
    
}
 
const FourOhFour: FunctionComponent<FourOhFourProps> = () => {
    document.title = "Endless Ways: Not Found";
    //console.log("route match", useRouteMatch());
    return ( <h1>Not found</h1> );
}
 
export default FourOhFour;
