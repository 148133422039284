import { FunctionComponent } from "react";
import { ReactComponent as ProgressBarSVG } from '../assets/progressBar.svg';
import BigNumber from 'bignumber.js';


type ArtworkMintProgressProps = {
    mintCount: BigNumber;
    maxMintCount: BigNumber; 
}

const ArtworkMintProgress: FunctionComponent<ArtworkMintProgressProps> = (props) => {

    const widthPct = props.mintCount.dividedBy(props.maxMintCount).toNumber();

    return (
        <div className="artwork-mint-progress" >
            <div className="progress-bar-box" >
                <ProgressBarSVG preserveAspectRatio="none" style={{width: (widthPct*100)+'%', height: "100px", position: "relative", top: "-5px"}} />
            </div>
            <div>{props.mintCount.toString()}/{props.maxMintCount.toString()} minted</div>

        </div>
     )
}

export default ArtworkMintProgress;