import ContractOracle from '../engine/endlessways-common-js/ContractOracle';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import ArtworkInfo from '../engine/endlessways-common-js/ArtworkInfo';
import axios from 'axios';
import Mint, { MintDisplayConfiguration } from './Mint';
import BigNumber from 'bignumber.js';
import AddressOrTzktAlias from './AddressOrTzktAlias';
import Avatar from './Avatar';

type CollectionProps = {
    contractOracle: ContractOracle
}

export function Collection(props: CollectionProps) {

    let params: { tzAddress: string } = useParams();

    const [tokenIds, setTokenIds] = useState<BigNumber[]>();
    const [artworks, setArtworks] = useState<ArtworkInfo[]>();

    useEffect(() => {
        (async () => {

            if (await props.contractOracle.getIndexerIsUp()) {
                const url = props.contractOracle.indexerBaseUrl + "/collection/" + params.tzAddress;
                const collectionData = await axios.get(url);
                const artworks = collectionData.data.artworks.map((artwork: any) => ContractOracle.demangleArtworkInfo(ContractOracle.fixTypes(artwork)));
                var tokenIds = collectionData.data.tokenIds.map((tokenId: string) => new BigNumber(tokenId));
                tokenIds.reverse();
                setTokenIds(tokenIds);
                setArtworks(artworks);    
            } else {

                const allContracts = props.contractOracle.getAllContractAddresses();
                const allTokenIdGetResults = await Promise.all(allContracts.map(async (contractAddress) => {
                    const searchParams = new URLSearchParams();
                    searchParams.append("token.contract", contractAddress);
                    searchParams.append("account", params.tzAddress);
                    searchParams.append("select", "token.tokenId,firstLevel");
                    const url = `https://api.${props.contractOracle.tzktNetworkName}.tzkt.io/v1/tokens/balances?` + searchParams.toString();
                    return await axios.get(url);
                }))
                const allTokenIdDatas = allTokenIdGetResults.flatMap((res) => res.data as any);
                allTokenIdDatas.sort((a,b) => a.firstLevel < b.firstLevel ? 1 : (a.firstLevel > b.firstLevel ? -1 : 0));
                const allTokenIds = allTokenIdDatas.map((d) => d["token.tokenId"]);
                //console.log("got token ids:", allTokenIds);
                const allArtworkIds = [...(new Set(allTokenIds.map((tokenId) => ContractOracle.getArtworkIdForTokenId(new BigNumber(tokenId))))).values()];
                //console.log("got artwork ids:", allArtworkIds);
                const artworks = await Promise.all(allArtworkIds.map(async (artworkId) => {
                    return await props.contractOracle.getArtwork(artworkId);
                }))
                //console.log("got artworks", artworks);
                setTokenIds(allTokenIds.map((t) => new BigNumber(t)));
                setArtworks(artworks.filter((a): a is ArtworkInfo => a !== undefined));

                // only token ids:
                // hz: https://api.hangzhou2net.tzkt/io/
                // https://staging.api.tzkt.io/v1/tokens/balances?token.contract=KT1VdCrmZsQfuYgbQsezAHT1pXvs6zKF8xHB&account=tz1ZR5JKcHzU8Xn5m3w3pMGHiYnHwQNPDkSm&select=token.tokenId


                // full:
                // "https://staging.api.tzkt.io/v1/tokens/balances?token.contract=KT1VdCrmZsQfuYgbQsezAHT1pXvs6zKF8xHB&account=tz1ZR5JKcHzU8Xn5m3w3pMGHiYnHwQNPDkSm"
            }

        })();
    }, [params.tzAddress, props.contractOracle]);

    return (
        <div className="collection">
            <div className="details">
                <Avatar address={params.tzAddress} />

                <div className="tezosAddress">{params.tzAddress}</div>
                <div className="h1">
                    <AddressOrTzktAlias address={params.tzAddress} default={"Collection"} prefix={"Collection of "} />
                </div>
            </div>
            {tokenIds && artworks ? 
                <div className="mints" /*style={{justifyContent: (tokenIds.length === 1) ? "center" : "left"}}*/>
                    {tokenIds.map((tokenId: BigNumber) => {
                        const tokenIdDecomposed = ContractOracle.getArtworkIdAndMintNumberForTokenId(tokenId);
                        const artwork = artworks.find((aw) => aw.id === tokenIdDecomposed.artworkId);
                        if (artwork) {
                            return <Mint artwork={artwork} key={tokenId.toString()} artworkId={artwork.id} mintNumber={tokenIdDecomposed.mintNumber.toString()} contractOracle={props.contractOracle} live={false} configuration={MintDisplayConfiguration.PrioritiseTitleAndShowArtist} />
                        } else {
                            return <div key={tokenId.toString()}>Can't find artwork for token {tokenId.toString()} ({JSON.stringify(tokenIdDecomposed)}</div>
                        }
                    })}
                </div>
            : 
                <h1 className="fade-in-out-2s" style={{textAlign: "center"}}>Loading data from contract...</h1>
            }
        </div>
    )

}



