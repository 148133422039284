import React from "react";

type ErrorBoundaryProps = {
  data?: any
};
type ErrorBoundaryState = {
    data?: any;
    hasError: boolean; // like this
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: any) {
      super(props);
      this.state = { hasError: false, data: props.data };
    }
  
    static getDerivedStateFromError(error: any) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error:any, errorInfo:any) {
      // You can also log the error to an error reporting service
      console.error("caught error:", error, "errorInfo:", errorInfo, "with data", this.state.data);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h1>Something went wrong.</h1>;
      }
  
      return this.props.children; 
    }
  }

export default ErrorBoundary;