import { FunctionComponent } from 'react';
import { ReactComponent as TwitterLogoSVG } from '../assets/twitterLogo.svg'
import { ReactComponent as DiscordLogoSVG } from '../assets/discordLogo.svg'
import { ReactComponent as MailLogoSVG } from '../assets/mailLogo.svg'
import { ReactComponent as EndlessWaysLogoSVG } from '../assets/ewyLogoFooter.svg'

type FooterProps = {
};

export const Footer: FunctionComponent<FooterProps> = (props) => {

    return (
        <div className="footer">
            <div className="elements">
                <EndlessWaysLogoSVG className="ewy-logo" />
                <div className="credits">
                    <div>© 2022 lia something gmbh. <a href="/impressum">imprint</a>.</div>
                    <div>design by <a href="https://www.thkdesign.at/">tin.hoc</a> | made with <a href='https://tezostaquito.io/'>taquito</a> and <a href='https://smartpy.io/'>smartpy</a></div>
                </div>
                <div className="external-links">
                    <a href="/newsletter">
                        <span className="newsletter">subscribe to our newsletter</span>
                        <MailLogoSVG className="logo" />
                    </a>
                </div>
                <div className="external-links">
                    <span className="follow-us">follow us on</span>
                    <a href="https://twitter.com/EndlessWays_TZ">
                        <TwitterLogoSVG className="logo" />
                    </a>
                    <a href="https://discord.gg/AQn8E5ddvS">
                        <DiscordLogoSVG className="logo" />
                    </a>
                </div>
            </div>
        </div>
    );
}


export default Footer;