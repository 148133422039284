import { useEffect, useMemo, useState } from 'react';
import ContractOracle from '../engine/endlessways-common-js/ContractOracle';
import BigNumber from 'bignumber.js';
import { useRouteMatch } from 'react-router-dom';

type TokenSeedProps = {
    contractOracle: ContractOracle
};


function TokenSeed(props: TokenSeedProps) {

    const routeMatch = useRouteMatch();

    const tokenId = useMemo(() => {
        return new BigNumber((routeMatch.params as any).tokenId);
    }, [routeMatch]);

    const [tokenSeed, setTokenSeed] = useState<string>();

    useEffect(() => {
        async function getTokenSeed() {
            try {
                // for token id", tokenId.toString());
                const seed = await props.contractOracle.getTokenSeed(tokenId);
                if (!seed) {
                    setTokenSeed("not found");
                } else {
                    //console.log('fetched token seed ', seed);
                    setTokenSeed(seed);
                }
            } catch (err) {
                console.error("error getting seed:", err);
            }
        }
        getTokenSeed();
    }, [props.contractOracle, tokenId]);

    return (
        <div>
            <div>Token id: {tokenId.toString()}</div>
            <div>Seed: {tokenSeed ?? "loading..."}</div>
        </div>
    )

}

export default TokenSeed;