import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { BeaconWallet } from '@taquito/beacon-wallet';
import ArtworkInfo from '../engine/endlessways-common-js/ArtworkInfo';
import Mint from './Mint';
import { useHistory, useRouteMatch } from "react-router";
import { useUserAddressContext } from '../engine/UserAddressContext';
import ArtworkMintUI, { ArtworkMintState } from './ArtworkMintUI';
import { Link } from "react-router-dom";
import BigNumber from 'bignumber.js';
import ContractOracle from '../engine/endlessways-common-js/ContractOracle';
import ArtworkMintProgress from './ArtworkMintProgress';
import Avatar from './Avatar';

//import useTraceUpdate from '../engine/TraceUpdate';
import FourOhFour from './FourOhFour';
import TreeAnimation from './TreeAnimation';
import OutsideAlerter from './OutsideAlerter';
import Modal from 'react-modal';
import HoverImage from './HoverImage';

interface ArtworkGalleryProps {
    contractOracle: ContractOracle
    wallet?: BeaconWallet
    artwork: ArtworkInfo
    refreshMintCountCallback: (artworkId: number) => Promise<ArtworkInfo | undefined>
}

function buildMintsToShowList(startMintNumber: BigNumber, count: number, artworkMintCount: BigNumber, reverseMintOrder: boolean): BigNumber[] {
    const endMintNumber = BigNumber.min(startMintNumber.plus(count-1), artworkMintCount);

    var currentMintNumber = new BigNumber(startMintNumber);
    var mintNumbersToShow = new Array<BigNumber>();
    while (currentMintNumber.isLessThanOrEqualTo(endMintNumber)) {
        mintNumbersToShow.push(currentMintNumber);
        currentMintNumber = currentMintNumber.plus(1);
    }

    if (reverseMintOrder) {
        mintNumbersToShow.reverse();
    }
    //console.log('mints to show', mintNumbersToShow.map((n) => n.toString()));
    return mintNumbersToShow;
}

const ArtworkGallery: FunctionComponent<ArtworkGalleryProps> = (props) => {
    //useTraceUpdate("ArtworkGallery", props);
    const userAddress = useUserAddressContext();
    
    const routeMatch = useRouteMatch();
    const pausedAndNotArtist = (props.artwork.is_paused && (props.artwork.artist_address !== userAddress));
    const routeSeed = (props.artwork.selectSeedOnMint && !pausedAndNotArtist) ? (routeMatch.params as any).previewSeed : undefined;
    //const routeSeed = (routeMatch.params as any).previewSeed

    //=> {foo: 'bar'}    console.log('search params', searchParams);

    const [currentMintPageNumber, setCurrentMintPageNumber] = useState(0);
    
    const kMintsPerPage = 12;
    const kReverseMintOrder = true;

    const artwork = props.artwork;
    const mintCountAsString = artwork.mint_count.toString();

    const [isVisible, setIsVisible] = useState<boolean>(artwork.isVisible || userAddress === artwork.artist_address);
    const [mintsToShow, setMintsToShow] = useState<BigNumber[]>([]);
    const [lastMintPageNumber, setLastMintPageNumber] = useState<number>(0);
    const [mintPages, setMintPages] = useState<number[]>([]);

    useEffect(() => {
        let lastPageNumber = (new BigNumber(mintCountAsString).minus(1).dividedToIntegerBy(kMintsPerPage)).toNumber();
        setLastMintPageNumber(lastPageNumber);
        setCurrentMintPageNumber(lastPageNumber);

        const pages = [];
        for (var i=0; i<=lastPageNumber; i++) {
            pages.push(i);
        }
        setMintPages(pages);

    }, [kMintsPerPage, mintCountAsString]);

    useEffect(() => {

        const getFirstMint = () => {
            const first = new BigNumber(currentMintPageNumber * kMintsPerPage).plus(1);
            if (!kReverseMintOrder) {
                return first;
            }
            const remainder = totalMintCount.modulo(kMintsPerPage);
            //console.log("page number", currentMintPageNumber, "first", first.toNumber(), "remainder", remainder.toNumber());
            if (remainder.eq(0)) {
                return first;
            } else {
                return first.plus(remainder).minus(kMintsPerPage);
            }
        }

        const totalMintCount = new BigNumber(mintCountAsString);
        var firstMintToShow = getFirstMint();
        var count = kMintsPerPage;
        if (firstMintToShow.lt(1)) {
            count -= -firstMintToShow.toNumber() + 1;
            firstMintToShow = new BigNumber(1);
        }
        setMintsToShow(buildMintsToShowList(firstMintToShow, count, totalMintCount, kReverseMintOrder));
    }, [currentMintPageNumber, kReverseMintOrder, mintCountAsString]);

    const [canEdit, setCanEdit] = useState<Boolean>(false);
    const [showEditButton, setShowEditButton] = useState<Boolean>(false);

    useEffect(() => {
        if (!isVisible) {
            (async () => {
                const adminAddress = await props.contractOracle.getAdminWalletAddress();
                if (adminAddress === userAddress) {
                    setIsVisible(true);
                }
            })();
        }
        //console.log("ArtworkGallery hit with artwork", artwork);
    }, [isVisible, props.contractOracle, userAddress]);

    useEffect(() => {
        if (isVisible) {
            document.title = `Endless Ways: ${artwork.title} by ${artwork.artist}`;
        } else {
            document.title = `Endless Ways: Not found`;
        }
    }, [artwork, isVisible]);

    useEffect(() => {
        (async () => {
            const newCanEdit = ((userAddress ?? "").length > 0) && ((userAddress === artwork.artist_address) || (userAddress === artwork.archivist));
            setCanEdit(newCanEdit);
        })();
    }, [artwork.artist_address, artwork.archivist, userAddress, props.contractOracle.contractDirectAccess, props.contractOracle])

    const [previewTokenSeed, setPreviewTokenSeed] = useState<string | undefined>(() => {
        //console.log("build previewTokenSeed with routeSeed", routeSeed);
        return routeSeed;
    });
    const [previewMintNumber, setPreviewMintNumber] = useState<BigNumber>();
    const history = useHistory();
    const [showMintPendingAnimation, setShowMintPendingAnimation] = useState<boolean>();
    const [mintState, setMintState] = useState<ArtworkMintState>(ArtworkMintState.Idle);

    useEffect(() => {
        //console.log("updated previewTokenSeed with routeSeed", routeSeed);
        setPreviewTokenSeed(routeSeed);
        const newMintNumber = props.artwork.mint_count.plus((routeSeed) ? 1 : 0);
        setPreviewMintNumber(newMintNumber);
    }, [props.artwork.mint_count, routeMatch.params, routeSeed]);

    function makeNewSeed(): string {
        var newSeed = "";
        for (var i=0; i<32; i++) {
            const byte = Math.floor(255.99999999*Math.random());
            var zerofilled = ('0'+byte.toString(16)).slice(-2)
            newSeed += zerofilled;
        }
        setPreviewTokenSeed(newSeed);
        history.push({ pathname: `/artworks/${props.artwork.id}/${newSeed}`});
        const newMintNumber = props.artwork.mint_count.plus(1);
        setPreviewMintNumber(newMintNumber);
        
        return newSeed;
    }

    const liveFrameMintNumber = useMemo(() => {
        const lfmn = previewMintNumber ?? new BigNumber(mintCountAsString);
        if (lfmn.isEqualTo(0)) {
            return undefined;
        }
        //console.log("updated live frame mint number to ", lfmn.toString());
        return lfmn;
    }, [mintCountAsString, previewMintNumber]);


    function prevMintPageButtonPressed() {
        setCurrentMintPageNumber(Math.max(0, currentMintPageNumber - 1));
    }
    function nextMintPageButtonPressed() {
        setCurrentMintPageNumber(Math.min(lastMintPageNumber, currentMintPageNumber + 1));
    }

    //console.log("rendering with override seed", previewTokenSeed);

    //console.log("showing mints:", mintsToShow);
    /*
                                <div>Mint pending...</div>

<div>Your newly-created mint will appear here.</div>*/
    return (
        isVisible ? 
            <div className="artwork-gallery">
                <div className="hero-card" 
                    onMouseEnter={() => {canEdit && setShowEditButton(true)}}
                    onMouseLeave={() => setShowEditButton(false)} 
                    >
                    <div className="live-frame" style={{ "aspectRatio" : `${artwork.aspectRatio}` }}>
                        {showMintPendingAnimation ? (
                            <div className="mint-pending">
                                <TreeAnimation />
                                <div className="status-text">
                                    { (mintState === ArtworkMintState.Idle) ? "New mints appear here." : 
                                        ( (mintState === ArtworkMintState.Requesting) ? "Mint requested..." : "Waiting for the mint to complete...") }
                                </div>
                            </div>
                        ) : (liveFrameMintNumber ? (
                            <Mint artwork={artwork} artworkId={artwork.id} mintNumber={liveFrameMintNumber.toString()} overrideSeed={previewTokenSeed} contractOracle={props.contractOracle} live={true} />
                        ) : (
                            <span>No mints yet.</span>
                        ))}
                    </div>
                    <div className="artwork-details" >
                        <div className="align-to-top" >
                            <Avatar address={artwork.artist_address} />
                            <div className="title">{artwork.title}</div>
                            <div className="artist">by {artwork.artist}
                                {artwork.artistExtraLink && <a href={artwork.artistExtraLink} target="_blank" rel="noreferrer noopener" >
                                    <HoverImage staticUrl="/images/externalLinkButton.svg" hoverUrl="/images/externalLinkButton-over.svg" altText="external link icon" className="artist-link" />
                                </a>}
                                {artwork.artistTwitter && <a href={artwork.artistTwitter} target="_blank" rel="noreferrer noopener" >
                                    <HoverImage staticUrl="/images/twitterButton.svg" hoverUrl="/images/twitterButton-over.svg" altText="twitter icon" className="artist-link" />
                                </a>}
                            </div>
                            <DescriptionWithMore description={artwork.description} />
                            {artwork.rights && <>
                                <div className="rights-header">Token-holder's usage rights:</div>
                                <div className="rights">{artwork.rights}</div>
                            </>}
                        </div>
                        <div className="align-to-bottom" >
                            <ArtworkMintUI 
                                artwork={artwork} 
                                contractOracle={props.contractOracle} 
                                wallet={props.wallet} 
                                refreshMintCountCallback={props.refreshMintCountCallback} 
                                seed={previewTokenSeed} 
                                makeNewSeed={makeNewSeed} 
                                setShowMintPendingAnimation={setShowMintPendingAnimation}
                                setMintState={setMintState}
                                />
                            <ArtworkMintProgress mintCount={artwork.mint_count} maxMintCount={artwork.max_mint_count}/>
                        </div>
                    </div>
                    {showEditButton && <div className="edit-button">
                        <Link to={`/artworks/${props.artwork.id}/edit`}><i className="fas fa-edit"></i></Link>
                    </div>}

                </div>
                {(mintPages.length > 1) && 
                    <div className="mints-page-selector">
                        <button className="looks-like-link next-prev" onClick={prevMintPageButtonPressed}>&lt;</button>
                        {mintPages.map(mintPageNumber => {
                            const className = (mintPageNumber === currentMintPageNumber) ? " current" : "";
                            return <button key={mintPageNumber} className={"looks-like-link" + className} onClick={(e) => {setCurrentMintPageNumber(mintPageNumber)}}>{mintPageNumber+1}</button>
                        })}
                        <button className="looks-like-link next-prev" onClick={nextMintPageButtonPressed}>&gt;</button>
                    </div>
                }
                <div className="mints">
                    {mintsToShow.map(mintNumber => {
                        return <Mint artwork={artwork} key={mintNumber.toString()} artworkId={artwork.id} mintNumber={mintNumber.toString()} contractOracle={props.contractOracle} live={false} />
                    })}
                </div>
            </div>
     : <FourOhFour />
    );
}

type DescriptionWithMoreProps = {
    description: string;
}

const DescriptionWithMore: FunctionComponent<DescriptionWithMoreProps> = (props) => {

    const descriptionRef = useRef<HTMLDivElement>(null);
    const [ellipsisIsActive, setEllipsisIsActive] = useState<boolean>(false);
    const [showMore, setShowMore] = useState<boolean>(false);

    function isEllipsisActive(e: HTMLDivElement) {
        //console.log("checking ellipses: offset height", e.offsetHeight, "scroll height", e.scrollHeight);
        return ((e.scrollHeight - e.offsetHeight) > 5);
    }

    useEffect(() => {
        if (descriptionRef.current !== null) {
            const ellipsisIsActive = isEllipsisActive(descriptionRef.current);
            //console.log("in descriptionRef useEffect: ellipsis on", descriptionRef.current, "is active:", ellipsisIsActive);
            setEllipsisIsActive(ellipsisIsActive);
        }
    }, [descriptionRef]);

    return (
        <>
            {/*ellipsisIsActive && <button onClick={(e) => setShowMore(!showMore)} className="more-indicator">{showMore ? ">" : "<"}</button>*/}
            <div ref={descriptionRef} className="description">{props.description}
                {ellipsisIsActive && <button className="more-button" onClick={(e) => setShowMore(true)}>read more...</button>}
            </div>

            <Modal isOpen={showMore} className="modal-outer" >
                <OutsideAlerter callback={() => setShowMore(false)}>
                    <div className="modal-inner description-more">{props.description}
                        <button className="looks-like-link close-button" onClick={() => setShowMore(false)}>&lt;</button>
                    </div>
                </OutsideAlerter>
            </Modal>
            
        </>
    )

}

export default ArtworkGallery;