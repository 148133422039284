import { MichelsonMap, TezosToolkit } from '@taquito/taquito';
import ArtworkInfo, { RoyaltyInfo } from './ArtworkInfo';
import BigNumber from 'bignumber.js';
import ContractDirectAccess from './ContractDirectAccess';
/*import { BeaconWallet } from '@taquito/beacon-wallet';
import { TezosOperationType } from '@airgap/beacon-sdk';*/
const axios = require('axios');

const TEN_BILLION = '10000000000';

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export type Config = {
    tezosToolkit: TezosToolkit
    indexerBaseUrl: string
    baseImagesUri?: string
    baseLiveUri?: string
    tzktNetworkName?: string
}

export interface ContractInfo {
    firstArtworkId: number
    contractAddress: string
    properties: {
        setSeedOnMint: boolean
        separateScriptAndMintCount: boolean
    }
}

export class ContractOracle {

    tezosToolkit: TezosToolkit
    indexerBaseUrl: string
    baseImagesUri?: string
    baseLiveUri?: string
    tzktNetworkName?: string

    contractDirectAccess: ContractDirectAccess[] = [];

    constructor(config: Config) {
        //console.log("ContractOracle config:", config);
        this.tezosToolkit = config.tezosToolkit;
        this.indexerBaseUrl = config.indexerBaseUrl;
        this.baseImagesUri = config.baseImagesUri;
        this.baseLiveUri = config.baseLiveUri;
        this.tzktNetworkName = config.tzktNetworkName;
    }

    async setupDirectContractAccess(contractInfos: ContractInfo[]): Promise<void> {
        contractInfos.sort((a, b) => a.firstArtworkId - b.firstArtworkId);
        const contractDirectAccess = contractInfos.map((contractInfo, index) => {
            let lastArtworkId = ((index + 1) < contractInfos.length) ?
                (contractInfos[index + 1].firstArtworkId - 1) :
                undefined;
            return new ContractDirectAccess({
                tezosToolkit: this.tezosToolkit,
                contractAddress: contractInfo.contractAddress,
                firstArtworkId: contractInfo.firstArtworkId,
                lastArtworkId: lastArtworkId,
                separateScriptAndMintCount: contractInfo.properties.separateScriptAndMintCount
            })
        })

        //await Promise.all(contractDirectAccess.map((cda) => cda.setup()));
        //console.log("assigning contract direct access", contractDirectAccess);
        this.contractDirectAccess = contractDirectAccess;
    }

    getActiveContractDirectAccess(): ContractDirectAccess | undefined {
        return this.contractDirectAccess.find((cda) => cda.lastArtworkId === undefined);
    }

    async getContractDirectAccess(artworkId: number, options: { needsSetup: boolean } = { needsSetup: true }): Promise<ContractDirectAccess | undefined> {
        //console.log("looking for ", artworkId, "in", this.contractDirectAccess);
        const cda = this.contractDirectAccess.find((cda) => cda.firstArtworkId <= artworkId && (!cda.lastArtworkId || (artworkId <= cda.lastArtworkId)));
        if ((cda != null) && options.needsSetup) {
            return cda.setup();
        }
        return cda;
    }

    getAllContractAddresses(): string[] {
        return this.contractDirectAccess.map(cda => cda.contractAddress);
    }

    /*
    async mint(artwork: ArtworkInfo, wallet: BeaconWallet): Promise<any> {
        const cda = await this.getContractDirectAccess(artwork.id, { needsSetup: false });
        if (cda === undefined) {
            throw new Error("no CDA");
        }
        try {
            const client = wallet.client;
            const result = await client.requestOperation({
              operationDetails: [
                {
                  kind: TezosOperationType.TRANSACTION,
                  amount: artwork.price_to_mint,
                  destination: cda.contractAddress,
                  parameters: {
                    entrypoint: "mint_and_purchase",
                    value: {
                      int: artwork.id.toString(),
                    },
                  },
                },
              ],
            });
            console.log(result);
            return result;
        } catch (error: any) {
            throw error;
        }
    }*/

    async getActiveStorage(): Promise<any> {
        while (true) {
            const storage = await this.getActiveContractDirectAccess()?.getStorage();
            if (storage) {
                return storage;
            }
            await sleep(500);
        }
    }

    getBaseImagesUri() {
        /*const storage = await this.getActiveStorage();
        return ContractOracle.hexToUtf8String(await storage.metadata.get("base_images_uri"));*/
        return this.baseImagesUri ?? "";
    }

    getThumbnailUri(tokenId: BigNumber, options?: { noRedirect: boolean }): string {
        const url = new URL(`thumbnail/${tokenId.toString()}`, this.baseImagesUri ?? "");
        if (options?.noRedirect) {
            url.searchParams.append('noRedirect', '1');
        }
        return url.toString();
    }

    getDisplayImageUri(tokenId: BigNumber, options?: { noRedirect: boolean }): string {
        const url = new URL(`displayImage/${tokenId.toString()}`, this.baseImagesUri ?? "");
        if (options?.noRedirect) {
            url.searchParams.append('noRedirect', '1');
        }
        return url.toString();
    }

    getLiveUri(tokenId: BigNumber): string {
        const url = new URL(`${tokenId.toString()}`, this.baseLiveUri ?? "");
        return url.toString();
    }

    async getArtworkCount(): Promise<number> {
        var count = 0;
        for (var i = 0; i < this.contractDirectAccess.length; i++) {
            count += await this.contractDirectAccess[i].getArtworkCount();
        }
        return count;
    }

    async getAdminWalletAddress(): Promise<String> {
        let storage = await this.getActiveStorage();
        return storage.administrator;
    }

    async getArchivistWalletAddress(): Promise<String> {
        let storage = await this.getActiveStorage();
        return storage.archivist;
    }

    async isAdmin(walletAddress: String): Promise<boolean> {
        return walletAddress === (await this.getAdminWalletAddress());
    }

    async isArchivist(walletAddress: String): Promise<boolean> {
        return walletAddress === (await this.getArchivistWalletAddress());
    }

    static parseSecondaryRoyaltyInfoFromJson(secondaryRoyaltiesJson: string): RoyaltyInfo {
        //console.log("parsing secondary royalties", secondaryRoyaltiesJson);
        var parsed = JSON.parse(secondaryRoyaltiesJson);
        //console.log("parsed", parsed, "; type of parsed.shares is", (parsed.shares instanceof Array) ? 'array' : 'object');
        const shares = (parsed.shares instanceof Array) ? /* this version is wrong but here for legacy reasons */ parsed.shares.map((share: any) => {
            return {
                "0": share.address,
                "1": new BigNumber(share.share)
            }
        }) : /* this version is correct */ Object.entries(parsed.shares).map(([address, share]) => { 
            return { 
                "0": address, 
                "1": new BigNumber(share as number) 
            } 
        });
        return {
            decimals: new BigNumber(parsed.decimals),
            shares: shares
        }
    }

    static hexToBytes(hex: string): Buffer {
        for (var bytes = [], c = 0; c < hex.length; c += 2)
            bytes.push(parseInt(hex.substr(c, 2), 16));
        return Buffer.from(bytes);
    }

    static hexToUtf8String(hex: string): string {
        const bytes = Buffer.from(hex, 'hex');
        return bytes.toString();
    }

    static utf8StringToHex(string: string): string {
        const bytes = Buffer.from(string, 'utf8');
        return bytes.toString('hex');
    }

    async getTokenSeed(tokenId: BigNumber, options?: { forceRefresh: boolean }): Promise<string | undefined> {
        if (await this.getIndexerIsUp()) {
            return await this.getTokenSeedFromIndexer(tokenId, options);
        } else {
            return await this.getTokenSeedFromContract(tokenId);
        }
    }

    async getTokenSeedFromIndexer(tokenId: BigNumber, options?: { forceRefresh: boolean }): Promise<string | undefined> {
        const url = this.indexerBaseUrl + "/tokenSeed/" + tokenId.toString() + (options?.forceRefresh ? `?timestamp=${new Date().getTime()}` : '');
        const seedData = await axios.get(url, {
            validateStatus: function (status: number) {
                return (status >= 200 && status < 300) /* default */ || status === 404;
            }
        });
        if (seedData.status === 404) {
            return undefined;
        }
        const seed = seedData.data;
        return seed;
    }

    async getTokenSeedFromContract(tokenId: BigNumber): Promise<string | undefined> {
        const artworkId = ContractOracle.getArtworkIdForTokenId(tokenId);
        const cda = await this.getContractDirectAccess(artworkId);
        return cda?.getTokenSeed(tokenId);
    }

    async getIndexerHasIndexedLevel(level: number): Promise<boolean> {
        const url = this.indexerBaseUrl + "/isLevelIndexed/" + level.toString();
        try {
            const indexedData = await axios.get(url);
            //console.log("indexer", url, "result", indexedData);
            return indexedData.data; // 'true' or 'false'
        } catch (error: any) {
            console.error("Error getting " + url + ": " + error.name + " - " + error.message);
            return false;
        }
    }

    async getIndexerIsUp(): Promise<boolean> {
        const url = this.indexerBaseUrl + "/isIndexerUp";
        try {
            const indexerUpData = await axios.get(url);
            //console.log("indexer", url, "result", indexedData);
            return indexerUpData.data; // 'true' or 'false'
        } catch (error: any) {
            console.error("Error getting " + url + ": " + error.name + " - " + error.message);
            return false;
        }
    }


    async getArtwork(artworkId: number, options?: { forceRefresh: boolean }): Promise<ArtworkInfo | undefined> {
        if (await this.getIndexerIsUp()) {
            return this.getArtworkFromIndexer(artworkId, options);
        } else {
            return this.getArtworkFromContract(artworkId);
        }
    }

    async getArtworks(startArtworkId: number, count: number): Promise<ArtworkInfo[]> {
        if (await this.getIndexerIsUp()) {
            return this.getArtworksFromIndexer(startArtworkId, count);
        } else {
            return this.getArtworksFromContract(startArtworkId, count);
        }
    }

    async getArtworkFromIndexer(artworkId: number, options?: { forceRefresh: boolean }): Promise<ArtworkInfo | undefined> {
        const url = this.indexerBaseUrl + "/artwork/" + artworkId.toString() + (options?.forceRefresh ? `?timestamp=${new Date().getTime()}` : '');
        const artworkData = await axios.get(url);
        const artwork = artworkData.data;
        return ContractOracle.demangleArtworkInfo(ContractOracle.fixTypes(artwork));
    }

    async getArtworksFromIndexer(startArtworkId: number, count: number): Promise<ArtworkInfo[]> {
        const url = this.indexerBaseUrl + "/artworks/" + startArtworkId.toString() + "-" + (startArtworkId + (count - 1)).toString();
        const artworksData = await axios.get(url);
        const artworks = artworksData.data;
        //console.log("got artworks", artworks);
        return artworks.map((artwork: ArtworkInfo) => ContractOracle.demangleArtworkInfo(ContractOracle.fixTypes(artwork)))
    }


    async getArtworkFromContract(artworkId: number, forceRefresh: boolean = false): Promise<ArtworkInfo | undefined> {
        //console.log("getting artwork ", artworkId);
        const artworks = (await this.getArtworksFromContract(artworkId, 1, forceRefresh));
        //console.log("getArtworkFromContract got", artworks);
        const artwork = artworks.at(0);
        //console.log("got ", artwork);
        return artwork;
    }

    subdivideArtworksForContractDirectAccess(startArtworkId: number, endArtworkId: number): { range: { start: number, end: number }, cda: ContractDirectAccess }[] {
        var result = [];
        var currentStart = startArtworkId;
        //console.log("subdividing for ", startArtworkId, " - ", endArtworkId, "with", this.contractDirectAccess[0]);
        if (currentStart < this.contractDirectAccess[0].firstArtworkId) {
            currentStart = this.contractDirectAccess[0].firstArtworkId;
        }
        for (var i = 0; i < this.contractDirectAccess.length; i++) {
            const cda = this.contractDirectAccess[i];
            //console.log("currentStart is ", currentStart, "looking at", cda);

            if (cda.firstArtworkId <= currentStart) {
                if (cda.lastArtworkId && cda.lastArtworkId < currentStart) {
                    continue;
                }
                // we're using this contract
                //console.log("-> using");

                const thisStart = currentStart;
                const thisEnd = Math.min(endArtworkId, (cda.lastArtworkId ?? endArtworkId));
                result.push({ range: { start: thisStart, end: thisEnd }, cda: cda });
                currentStart = thisEnd + 1;
                if (currentStart > endArtworkId) {
                    break;
                }
            }
        }
        return result;
    }

    async getArtworksFromContract(startArtworkIdIn: number, countIn: number, forceRefresh: boolean = false): Promise<ArtworkInfo[]> {
        if (this.contractDirectAccess.length === 0) {
            return []
        }
        var subdivisions = this.subdivideArtworksForContractDirectAccess(startArtworkIdIn, startArtworkIdIn + countIn - 1);
        //console.log('got subdivisions', subdivisions);
        //console.log("getting artworks", startArtworkIdIn, "-", startArtworkIdIn+countIn-1, "using contract direct access");
        const artworks = await Promise.all(subdivisions.map((sd) => sd.cda.getArtworks(sd.range.start, (sd.range.end - sd.range.start) + 1, forceRefresh)));
        //console.log("getArtworksFromContract got", artworks);

        return artworks.flat().map((info) => ContractOracle.demangleArtworkInfo(info));
    }

    static fixTypes(artwork: any): ArtworkInfo {
        if (typeof (artwork.id) == "string") {
            artwork.id = parseInt(artwork.id);
        }
        artwork.extra_data = ContractOracle.convertArrayToMichelsonMap(artwork.extra_data);
        artwork.token_metadata = ContractOracle.convertArrayToMichelsonMap(artwork.token_metadata);
        artwork.max_mint_count = new BigNumber(artwork.max_mint_count);
        artwork.mint_count = new BigNumber(artwork.mint_count);

        var royalties = artwork.primary_royalties as any;
        if (royalties) {
            royalties.decimals = new BigNumber(royalties.decimals);
            royalties.shares = royalties.shares.map((share: any) => { return { 0: share.address, 1: new BigNumber(share.share) } })
        }

        return artwork
    }

    static demangleArtworkInfo(info: ArtworkInfo): ArtworkInfo {
        // unpack script utf8 bytes
        if (info.script) {
            try {
                info.script = ContractOracle.hexToUtf8String(info.script);
            } catch (error) {
                console.error("error decoding script string");
                const errorObj = error as Error;
                if (errorObj) {
                    info.script = errorObj.message;
                } else {
                    info.script = "error decoding script: " + JSON.stringify(errorObj, Object.getOwnPropertyNames(errorObj));
                }
            }
        }
        // pull extra values from metadata
        const extra_data = info.extra_data;

        function getExtraData(key: string, defaultValue: (string | undefined) = "") {
            const bytes = extra_data.get(key) ?? info.token_metadata.get(key) ?? "";
            if (bytes.length === 0) {
                return defaultValue;
            }
            return ContractOracle.hexToUtf8String(bytes);
        }

        info.artist = getExtraData("artist", "undefined artist");
        info.title = getExtraData("title", "undefined title");

        info.description = getExtraData("description");

        const scriptTypeString = getExtraData("scriptTypeString", "p5.js");
        if (scriptTypeString === "p5.js") {
            info.scriptTypeString = "p5.js v1.4.0";
        } else {
            info.scriptTypeString = scriptTypeString;
        }

        const secondaryRoyaltiesJsonHexString = info.token_metadata.get("royalties");
        if (secondaryRoyaltiesJsonHexString) {
            const secondaryRoyaltiesJson = ContractOracle.hexToUtf8String(secondaryRoyaltiesJsonHexString);
            info.secondaryRoyalties = ContractOracle.parseSecondaryRoyaltyInfoFromJson(secondaryRoyaltiesJson);
        }
        //console.log("assigned script type string", info.scriptTypeString);
        info.waitTimeMillis = parseInt(getExtraData("waitTimeMillis", "1000"));
        info.isVisible = (getExtraData("isVisible", "false") === "true");
        info.selectSeedOnMint = (getExtraData("selectSeedOnMint", "0") === "1"); // yes it's inconsistent i know sorry

        info.artistTwitter = getExtraData("artistTwitter", undefined);
        info.artistExtraLink = getExtraData("artistExtraLink", undefined);
        info.rights = getExtraData("rights", undefined);
        info.aspectRatio = parseFloat(getExtraData("aspectRatio", "1"));

        return info;
    }

    static convertArrayToMichelsonMap(data: { 0: string, 1: string }[]): MichelsonMap<string, string> {
        var mm = new MichelsonMap<string, string>();
        data.forEach(kv => {
            mm.set(kv["0"], kv["1"]);
        })
        return mm;
    }

    async refreshMintCount(artworkId: number): Promise<ArtworkInfo | undefined> {
        return await this.getArtwork(artworkId, { forceRefresh: true });
    }

    static getArtworkIdForTokenId(tokenId: BigNumber): number {
        const divider = new BigNumber(TEN_BILLION);
        return tokenId.idiv(divider).toNumber();
    }

    static getTokenIdForMint(artworkId: number, mintNumber: BigNumber): BigNumber {
        const multiplier = new BigNumber(TEN_BILLION);
        // 1-based
        return multiplier.times(artworkId).plus(mintNumber).minus(1);
    }

    static getMintNuberForTokenId(tokenId: BigNumber): BigNumber {
        return this.getArtworkIdAndMintNumberForTokenId(tokenId).mintNumber;
    }

    static getArtworkIdAndMintNumberForTokenId(tokenId: BigNumber): { artworkId: number, mintNumber: BigNumber } {
        const artworkId = this.getArtworkIdForTokenId(tokenId);
        const divider = new BigNumber(TEN_BILLION);
        // 1-based
        const mintNumber = tokenId.modulo(divider).plus(1);
        return { artworkId: artworkId, mintNumber: mintNumber };
    }


}



export default ContractOracle;
