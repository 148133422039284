import { FunctionComponent, useState } from 'react';

type HoverImageProps = {
    staticUrl: string,
    hoverUrl: string,
    altText: string,
    className?: string
    style?: React.CSSProperties
}

export const HoverImage: FunctionComponent<HoverImageProps> = (props) => {

    const [imageUrl, setImageUrl] = useState<string>(props.staticUrl);

    return <img className={props.className} src={imageUrl} onMouseEnter={(e) => setImageUrl(props.hoverUrl)} onMouseLeave={(e) => setImageUrl(props.staticUrl)} alt={props.altText} style={props.style} />
}

export default HoverImage;

