import { FunctionComponent } from 'react';
import OutsideAlerter from './OutsideAlerter';
import Modal from 'react-modal';

export type ConfirmMintProps = {
    isOpen: boolean
    artworkId: number
    artworkTitle: string
    artworkArtist: string
    walletAddress: string
    seed?: string
    acceptCallback: () => void
    cancelCallback: () => void

}


export const ConfirmMint:FunctionComponent<ConfirmMintProps> = (props) => {

    const seedInfo = props.seed ? (<>the seed you have selected, <span className='code'>{props.seed}</span>,</>) : "a randomly created seed";
    const secondaryMarketLink = <a href="https://objkt.com/">objkt.com</a>;
    
    //useBodyClass(`confirm-mint-modal`);
    
    return (
        <Modal isOpen={props.isOpen} className="modal-outer" >
            <OutsideAlerter callback={props.cancelCallback}>
                <div className="modal-inner confirm-mint-modal">
                    <h3>It's important that you understand what you're getting when you mint.</h3>
                    <div>When you mint, we create a new token that binds {seedInfo} to 
                        Endless Ways artwork #<span className='code'>{props.artworkId}</span> (<span className="artwork-title">{props.artworkTitle}</span> by {props.artworkArtist}). We then assign that token 
                        to your wallet address <span className='code'>{props.walletAddress}</span>.</div>
                    <div><span className='important'>You own this token.</span> You can sell or trade it on secondary markets like {secondaryMarketLink}, send it to another wallet address, hodl it, burn it, or do whatever else you like with it.</div>
                    <div><span className='important'>The artist retains full ownership of the artwork source code.</span> What the token 
                        lets you to do is to run the source code with your random seed and say: 
                        <blockquote>I own the token corresponding to running this code with this seed. 
                            Everyone can verify my ownership by consulting the Tezos blockchain.</blockquote> 
                        <span className='important'>Anyone else can still run the artwork source code using your seed</span>, but you will be the only person
                        who can claim to own it in a way that can be verified using the Tezos blockchain.
                    </div>
                    <div><span className='important'>The artwork source code and your seed are stored permanently on the Tezos blockchain.</span> When you download a PNG from our website, you're not actually downloading the artwork itself. Instead, the PNG you download is just a temporary image that our servers have generated using your seed. This image can stand in for the live version in cases where you're unable to run the code, but it is not the artwork itself.</div>
                    <div className="buttons">
                        <button onClick={props.acceptCallback}>I understand, let's mint!</button>
                        <button onClick={props.cancelCallback}>Cancel</button>
                    </div>
                </div>
            </OutsideAlerter>
        </Modal>
    )

}