import { CSSProperties, FunctionComponent } from 'react';

type AvatarProps = {
    address: string
    width?: number
    style?: CSSProperties
}

const Avatar: FunctionComponent<AvatarProps> = (props) => {

    return (
        <img 
            className="avatar" 
            width={props.width} 
            src={`/avatars/${props.address}.png`}
            style={props.style}
            onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null; // prevents looping
                target.src=`https://services.tzkt.io/v1/avatars/${props.address}`;
              }}
            alt={`TZKT avatar for ${props.address}`} />
    )
};

export default Avatar;