/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

type AboutProps = {

}

export const About: FunctionComponent<AboutProps> = (props) => {

    const secondaryMarketLink = <a href="https://objkt.com/">objkt.com</a>;

    return (
        <div className="body-text">
            <h1>Generative art. Proper curation.</h1>
            <p>Endless Ways is an NFT platform showcasing on-chain generative artwork on Tezos.</p>
            <p>Our goal is to nurture conversations about software-based art. We want to encourage artists, collectors, and audiences 
                to form collaborative, mutually supportive ties with one another, where generative art can evolve alongside the conversations people have about it.</p>
            <a href="#curation"><h3>Curation</h3></a>
            <a href="#tokens"><h3>Mints, tokens, artworks, and source code</h3></a>
            <a href="#metadata"><h3>Metadata and images</h3></a>
            <a href="#modify"><h3>Who can modify the metadata?</h3></a>
            <a href="#for-collectors"><h3>I am a collector - how do I get Endless Ways tokens?</h3></a>
            <a href="#for-artists"><h3>I am an artist - how can I put my artwork on Endless Ways?</h3></a>
            <a href="#who"><h3>Who is behind Endless Ways?</h3></a>

            <h2><a id="curation" />Curation</h2>
            <p>Artworks on Endless Ways are released as community-curated exhitions. You can read more about our curation model on <Link to="/curation">the Curation page</Link>.</p>
            <h2><a id="tokens" />Mints, tokens, artworks, and source code</h2>
            <p>Each artwork on Endless Ways takes the form of a <span className='important'>script</span>, stored permanently on the Tezos blockchain, and a collection of user-generated <span className='important'>mint</span>s of that script, each of which corresponds to running the script using a particular 256-bit <span className='important'>seed number</span> as input. </p>
            <p>When you mint, we create an FA2-compliant token on our contract that binds a particular seed number to the artwork that you are minting. We then assign that token to your wallet address. All of this happens verifiably on the Tezos blockchain.</p>
            <p><span className='important'>You own this token.</span> You can sell or trade it on secondary markets like {secondaryMarketLink}, send it to another wallet address, hodl it, burn it, or do whatever else you like with it.</p>
            <p><span className='important'>The artist retains full ownership of the artwork source code.</span> What the token 
                lets you to do is to run the artwork script with the seed number bound to your mint and say: </p>
            <blockquote>I own the token corresponding to running this script with this seed number. 
                Everyone can verify my ownership by consulting the Tezos blockchain.</blockquote> 
            <p><span className='important'>Anyone else can still run the artwork script using your seed number</span>, but you will be the only person
                who can claim to own it in a way that can be verified using the Tezos blockchain.
            </p>
            <p><span className='important'>The artwork source code and your seed are stored permanently on the Tezos blockchain.</span> When you download a PNG from our website, you're not actually downloading the artwork itself. Instead, the PNG you download is just a temporary image that our servers have generated by running the artwork script with your seed number. This image can stand in for the live version in cases where you're unable to run the code, but it is not the artwork itself.</p>
            <h2><a id="metadata" />Metadata and images</h2>
            <p>We store all the information needed to reconstruct each token's metadata on the Tezos blockchain, alongside the artwork script and each of the mint seed numbers. This means that the thumbnails and display images linked in the metadata are not actually an important part of the artwork. Instead, you should think of them as a temporary substitute for being able to see the code running live in your browser.</p>
            <p>Your token does not derive from these image files. Instead, the opposite is true: <span className='important'>the image files are derived from your token</span>. Your token binds the <span className='important'>artwork script</span> to your <span className='important'>seed number</span>. If someone runs the script with your seed, they can easily reconstruct the image files. And because both the script and the seed number are stored <span className='important'>permanently</span> and <span className='important'>publicly</span> on the Tezos blockchain, it will always be possible to run the script with your seed, <span className='important'>for as long as the Tezos blockchain continues to exist.</span></p> 
            <p><span className='important'>Unlike most NFTs, Endless Ways tokens have mutable metadata.</span> This is because we want to make sure that the thumbnails and display images continue to reflect the artwork code over the next five, ten, twenty, or more years as web standards and technologies evolve. If the artist needs to update the code to ensure it still works, we may also need to be able to change the way we provide thumbnails, and this may mean we need to change the URL.</p>
            <h2><a id="modfiy" />Who can modify the metadata?</h2>
            <p>The <span className='important'>artist</span> can make changes to the description of the artwork or its title. On our contract, there is also an <span className='important'>archivist</span> role which can be assigned either by the artist or the contract administrator wallet. The archivist is able to make changes on the artist's behalf.</p>
            <p><span className='important'>Nobody can change the binding between random seed and artwork, and nobody except the token owner can change the owner of the token.</span> The metadata may change, but the core principle that your token represents - the binding between seed and artwork - cannot change.</p>
            <p>The artist or archivist can make changes to the script after your token has been minted. This is to ensure that the code continues to work as web standards evolve and browsers change the way they interpret scripts. If a change is made to a script, the old version is still accessible, so you can always access prior versions of the artwork script if necessary.</p>
            <h2><a id="for-collectors" />I am a collector - how do I get Endless Ways tokens?</h2>
            <p>You can obtain Endless Ways tokens either by minting artworks yourself, or by buying the tokens on a secondary market such {secondaryMarketLink}. Endless Ways does not have its own marketplace, and at the moment we have no plans to build one.</p>
            <h2><a id="for-artists" />I am an artist - how can I put my artwork on Endless Ways?</h2>
            <p>To sell your artworks here, you need to be a part of an exhibition. This means that you need to network with someone who wants to put together an exhibition, or apply as a curator to make one yourself. For more information, see <Link to="/curation">the Curation page</Link>.</p>
            <p>If you need help looking for other artists to exhibit with, the best way is to <a href="https://discord.gg/AQn8E5ddvS">join our Discord</a>. We will do all that we can to make it easy for everyone to find a crew to exhibit with!</p>
            <h2><a id="who" />Who is behind Endless Ways?</h2>
            <p>Endless Ways is a project by <a href="https://damianstewart.com">Damian Stewart</a> and <a href="https://liaworks.com/">LIA</a>.</p>
        </div>
    )


}

export default About;