import { FunctionComponent, useEffect, useMemo, useState } from "react";
import BigNumber from 'bignumber.js';
import Mint from "./Mint";
import ContractOracle from "../engine/endlessways-common-js/ContractOracle";
import ArtworkInfo from '../engine/endlessways-common-js/ArtworkInfo';
import { useRouteMatch } from 'react-router';
import axios from "axios";
import { Link } from "react-router-dom";
import { BeaconWallet } from '@taquito/beacon-wallet';
import { BeaconError } from '@airgap/beacon-sdk';
import AddressOrTzktAlias from './AddressOrTzktAlias';
import Avatar from "./Avatar";
import HoverImage from "./HoverImage";
import { fetchTokenOwnerFromTzkt } from '../engine/TZKTProfile';

interface ArtifactProps {
    contractOracle: ContractOracle
    wallet?: BeaconWallet
}
 
const Artifact: FunctionComponent<ArtifactProps> = (props) => {

    const [artwork, setArtwork] = useState<ArtworkInfo>();
    const [mintNumber, setMintNumber] = useState<BigNumber>();
    const [ownerAddress, setOwnerAddress] = useState<string>("");
    const [rerollCount, setRerollCount] = useState<number>(0);

    const routeMatch = useRouteMatch();

    const tokenId = useMemo(() => {
        return new BigNumber((routeMatch.params as any).tokenId);
    }, [routeMatch]);

    useEffect(() => {
        //console.log("useeffect triggered");
        (async () => {
            if (await props.contractOracle.getIndexerIsUp()) {
                const url = props.contractOracle.indexerBaseUrl + "/token/" + tokenId;
                const tokenData = await axios.get(url);
                const ownerAddress = tokenData.data.owner;
                setOwnerAddress(ownerAddress);
                setArtwork(ContractOracle.demangleArtworkInfo(ContractOracle.fixTypes(tokenData.data.artwork)));
            } else {
                const artworkId = ContractOracle.getArtworkIdForTokenId(tokenId);
                setArtwork(await props.contractOracle.getArtwork(artworkId));
                const tokenOwner = await fetchTokenOwnerFromTzkt(tokenId, props.contractOracle);
                setOwnerAddress(tokenOwner.address);
            }
            const mintNumber = ContractOracle.getArtworkIdAndMintNumberForTokenId(tokenId).mintNumber;
            setMintNumber(mintNumber);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contractOracle]);

    useEffect(() => {
        if (artwork) {
            document.title = `Endless Ways: mint # ${mintNumber} of ${artwork.title} by ${artwork.artist}`;
        }
    }, [artwork, mintNumber]);

    const displayImageUri = props.contractOracle.getDisplayImageUri(tokenId);
    const contractAddress = props.contractOracle.getActiveContractDirectAccess()?.contractAddress;
    const fullscreenUri = props.contractOracle.getLiveUri(tokenId);
    const marketplaceUri = `https://objkt.com/asset/${contractAddress}/${tokenId.toString()}`;

    const [prevMintNumber, nextMintNumber] = (mintNumber && artwork ? [
        mintNumber.gt(1) ? mintNumber.minus(1) : undefined,
        mintNumber.lt(artwork.mint_count) ? mintNumber.plus(1) : undefined
    ] : [undefined, undefined])
    const [prevTokenId, nextTokenId] = (artwork ? [
        prevMintNumber ? ContractOracle.getTokenIdForMint(artwork.id, prevMintNumber) : undefined,
        nextMintNumber ? ContractOracle.getTokenIdForMint(artwork.id, nextMintNumber) : undefined
    ] : [undefined, undefined]);

    return ( 
        <div className="artifact">
            {(mintNumber && artwork) ? 
                <>
                    <div className="live-frame">
                        <div className="next-prev" >
                            {prevMintNumber && 
                            <a href={"/live/" + prevTokenId} 
                                style={{padding: "0px", marginLeft: "0px", marginRight: "26px"}}>
                                <img src="/images/prevButton.svg" alt="previous"/>
                            </a>}
                        </div>
                        <Mint contractOracle={props.contractOracle} artworkId={artwork.id} mintNumber={mintNumber.toString()} live={true} rerollCount={rerollCount} />
                        <div className="next-prev" >
                            {nextTokenId && 
                            <a href={"/live/" + nextTokenId} 
                                style={{padding: "0px", marginLeft: "26px", marginRight: "0px"}}>
                                <img src="/images/nextButton.svg" alt="next"/>
                            </a>}
                        </div>
                    </div>
                    <div className="details">
                        <div className="item">
                            <div className="label">Artwork title</div>
                            <Link to={`/artworks/${artwork.id}`}><div className="data">{artwork?.title}</div></Link>
                        </div>
                        <div className="item">
                            <div className="label">Artist</div>
                            <div className="data">
                                <Avatar address={artwork.artist_address} />
                                {artwork.artist}
                            </div>
                        </div>
                        <div className="item">
                            <div className="label">Mint number</div>
                            <div className="data">{mintNumber.toString()}/{artwork.max_mint_count.toString()}</div>
                        </div>
                        <div className="item">
                            <div className="label">Owned by</div>
                            <Link to={`/collection/${ownerAddress}`}>
                                <div className="data">
                                    <Avatar address={ownerAddress} />
                                    <AddressOrTzktAlias address={ownerAddress}/>
                                </div>
                            </Link>
                        </div>
                        {(artwork.rerolls_enabled && 
                        <RerollTokenUI contractOracle={props.contractOracle} tokenId={tokenId} artwork={artwork} wallet={props.wallet}
                            rerollFinishedCallback={() => setRerollCount(rerollCount + 1)}>

                        </RerollTokenUI>
                        )}
                        <div className="item">
                            <div className="buttons-container">
                                <div className="buttons">
                                    <a href={fullscreenUri}>
                                        <HoverImage staticUrl="/images/fullscreenButton.svg" hoverUrl="/images/fullscreenButton-over.svg" style={{width: "22px", height: "22px"}} altText="fullscreen icon" />
                                    </a>
                                    <a href={displayImageUri}>
                                        <HoverImage staticUrl="/images/pngButton.svg" hoverUrl="/images/pngButton-over.svg" style={{width: "22px", height: "22px"}} altText="PNG icon" />
                                    </a>
                                    <a href={marketplaceUri}>
                                        <HoverImage staticUrl="/images/externalLinkButton.svg" hoverUrl="/images/externalLinkButton-over.svg" style={{width: "22px", height: "22px"}} altText="external link icon" />
                                    </a>                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="description">
                        {artwork.description}
                    </div>
                </>
             : 
                <h1 className="fade-in-out-2s">loading token {tokenId.toString()}...</h1>
            }

        </div>
     );
}
 
export default Artifact;

interface RerollTokenProps {
    wallet?: BeaconWallet
    tokenId: BigNumber
    contractOracle: ContractOracle
    artwork?: ArtworkInfo
    rerollFinishedCallback: (() => void);
}

const RerollTokenUI: FunctionComponent<RerollTokenProps> = (props) => {

    const [isRerolling, setIsRerolling] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    async function doReroll() {
        try {
            if (props.wallet && props.artwork) {
                setError(undefined);
                setIsRerolling(true);
                const contract = (await props.contractOracle.getContractDirectAccess(props.artwork.id))?.contract;
                if (!contract) {
                    setError("Couldn't get contract");
                    setIsRerolling(true);
                    return;
                }
                // mint!
                //let op = await props.contractOracle.mint(props.artwork, props.wallet);
                //console.log("mint produced operation", op);
                //console.log("going to mint artwork", props.artwork.id, "with an amount of", props.artwork.price_to_mint, "mutez");
                let op = await contract.methods.reroll_seed(props.tokenId).send({amount: props.artwork.price_to_reroll, mutez: true})
                //startMintTimer();
                // wait for confirmation...
                const kConfirmationsToWaitFor = 1;
                let confirmationObservable = op.confirmationObservable(kConfirmationsToWaitFor);
                confirmationObservable.subscribe((data: any) => {
                    const level = data.block.header.level;
                    //console.log("operation found in level %d", level);
                    if (data.completed) {
                        const waitForIndexerAndThenClearTokenImage = async () => {
                            //console.log("checking indexer for level %d", level);

                            // if the indexer is down, then we don't need to wait for it because we will pull the data from the tezos node directly
                            const hasIndexed = (await props.contractOracle.getIndexerIsUp() ? await props.contractOracle.getIndexerHasIndexedLevel(level) : true)
                            if (!hasIndexed) {
                                //console.log("waiting for indexer to index level %d", level);
                                setTimeout(waitForIndexerAndThenClearTokenImage, 1000);
                            } else {
                                //console.log("indexer has indexed level %d", level);
                                //console.log("-> Calling clear token");

                                const baseImagesUri = props.contractOracle.getBaseImagesUri();
                                const clearUrl = baseImagesUri + "6E187E50-9653-4C07-90B2-31957398F4D9/clearToken/" + props.tokenId.toString();
                                //console.log("fetching " + clearUrl);
                                try {
                                    await axios.get(clearUrl);
                                } catch (error) {
                                    const errorObj = error as Error;
                                    if (errorObj) {
                                        setError(errorObj.message);
                                    } else {
                                        setError(JSON.stringify(error, Object.getOwnPropertyNames(error)));
                                    }
                                } finally {
                                    setIsRerolling(false);
                                    props.rerollFinishedCallback();
                                }
                    
                            } 
                        }
                        waitForIndexerAndThenClearTokenImage();
                    }
                });
           }
        } catch (error) {
            console.error("error doing reroll:", error);
            setIsRerolling(false);
            if (error instanceof BeaconError) {
                let beaconError = error as BeaconError;
                if (beaconError.name === "UnknownBeaconError" && beaconError.title === "Aborted") {
                    // aborted by the user
                } else {
                    setError(beaconError.fullDescription.description);
                }
            } else if (error instanceof Error) {
                setError((error as Error).message);
            } else {
                setError("Error doing transaction, check log for more details");
            }
        }
    }
    
    const noWallet = (! props.wallet);
    const tezosPrice = ((props.artwork?.price_to_reroll ?? 0)/1000000).toString();
    const rerollButtonText = (noWallet ? "connect wallet" : "re-roll for " + tezosPrice + "ꜩ");

    return <>
        <div className="item">
            <button className="reroll-button"
                disabled={isRerolling}
                onClick={doReroll}>
                {isRerolling ? (
                    <span>
                        <i className="fas fa-spinner fa-spin"></i>&nbsp; re-rolling...
                    </span>
                ) : (
                    <span>{rerollButtonText}</span>
                )}
            </button>
            <div className="error">{error}</div>
        </div>
    </>

}
