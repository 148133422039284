import { FunctionComponent } from 'react';
import treeAnimationData from "../assets/baumAnimation.json"
import Lottie from 'lottie-react';

type TreeAnimationProps = {

}

export const TreeAnimation: FunctionComponent<TreeAnimationProps> = (props) => {

    return (
        <Lottie animationData={treeAnimationData} loop={true} />
    )

}

export default TreeAnimation;