import { FunctionComponent, useEffect, useState } from 'react';
import { ExhibitionInfo, ExhibitionItemInfo } from './ExhibitionInfo';
import MintThumbnail from './MintThumbnail';
import ContractOracle from '../engine/endlessways-common-js/ContractOracle';
import ArtworkInfo from '../engine/endlessways-common-js/ArtworkInfo';
import BigNumber from 'bignumber.js';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import FourOhFour from './FourOhFour';
import Avatar from './Avatar';

type ExhibitionProps = {
    contractOracle: ContractOracle
}

export const Exhibition: FunctionComponent<ExhibitionProps> = (props) => {

    const routeMatch = useRouteMatch();
    const exhibitionId: string = (routeMatch.params as any).exhibitionId;
    const [exhibitionInfo, setExhibitionInfo] = useState<ExhibitionInfo>();

    useEffect(() => {
        (async () => {

            const artworkIds = [2, 3, 4, 5, 6];
            var artworks: (ArtworkInfo|undefined)[] = [];
            const promises = artworkIds.map(async (artworkId) => {
                const artwork = await props.contractOracle.getArtwork(artworkId);
                artworks[artworkId] = artwork;
            })

            await Promise.all(promises);

            const exhibitionInfo: ExhibitionInfo = {
                title: 'Showing Off',
                description: "Showcasing new and OG talent on Endless Ways. With this Exhibition I wanted to demonstrate that other approaches to generative art are possible beyond lifeless formal repetition. Instead, let's take a breath of life, and celebrate wild motion, unpredictable rearticulation, and tender moments.",
                curatorName: 'LIA',
                curatorAddress: 'tz1auPyXVaMy3GntdCQwH2VGNuFj6bAocj2v',
                curatorSignoff: 'LIA, Vienna, March 2022',
                items: [
                    {
                        artworkId: 2,
                        artwork: artworks[2]!,
                        curatorStatement: "A unique painterly work. Rich textures and sparse forms.",
                        artistBio: 'Sarah Ridgley is a generative artist based in the USA.  Her work focuses on reflecting the human hand in collaboration with the computer and themes from the natural world in a completely code-driven way.'
                    },
                    {
                        artworkId: 3,
                        artwork: artworks[3]!,
                        curatorStatement: 'A fascinating approach to color. Depth and chaos as the new normal.',
                        artistBio: 'Marta Verde is a Galician (Spain) visual artist, creative coder, educator and maker. Her artistic practice explores the indeterminate nature of the relationship between the organic and the electronic in the visual field with the use of noise, repetition, error and digital processing of analog signals in real time.'
                    },
                    {
                        artworkId: 4,
                        artwork: artworks[4]!,
                        curatorStatement: "An abundance of wild shapes and colours, inspired by local vineyards.",
                        artistBio: 'Anna Carreras is a generative artists and a creative coder based in Barcelona. She is interested in the complexity that can emerge from simple behaviours. She explores how deterministic algorithms can create unexpected complex images and draw art pieces that foster human memories and feelings.'
                    },
                    {
                        artworkId: 5,
                        artwork: artworks[5]!,
                        curatorStatement: 'An angular audiovisual puzzle that invites connection-making between notation and sound.',
                        artistBio: 'Sam Tsao is a Malaysian digital media artist and new music pianist. Their works are varied, combining playful interactivity and improvisation with simple shapes and vibrant colors. Coming initially from the world of classical music, they also collaborate with composers and performers to create audiovisual works. They are currently based in Saarbrücken, Germany.'
                    },
                    {
                        artworkId: 6,
                        artwork: artworks[6]!,
                        curatorStatement: "Tender and multifaceted organic forms slowly take over the canvas.",
                        artistBio: 'Alba G. Corral, based in Catalunya, is a Visual Artist and creative coder. With a background in computer engineering, Corral has been creating generative art using software and coding for the past decade. Her practice spans across live performance, video, digital media and installation.'
                    }
                ],
            }
            setExhibitionInfo(exhibitionInfo);
            document.title = `Endless Ways: Exhibition - ${exhibitionInfo.title}`;

        })();
    }, [props.contractOracle]);


    if (exhibitionId !== "1") {
        return <FourOhFour />
    }


    return (
        <div className="exhibition">
            {exhibitionInfo ? (<>
                <h1>{exhibitionInfo.title}</h1>
                <div className="curator">curated by <Avatar width={23} address={exhibitionInfo.curatorAddress} />{exhibitionInfo.curatorName}</div>
                <div className="curation-statement">“{exhibitionInfo.description}” - <Avatar width={23} style={{position: "relative", top: "4px"}} address={exhibitionInfo.curatorAddress} /> {exhibitionInfo.curatorSignoff}</div>

                <div className="exhibition-items">
                    {exhibitionInfo.items.map((info, index) => {
                        return <ExhibitionItem key={index} artwork={info.artwork as ArtworkInfo} contractOracle={props.contractOracle} info={info} />
                    })}
                </div>
            </>) : <h2 className="fade-in-out-2s">Loading data from contract...</h2>}
        </div>
    )
}

type ExhibitionItemProps = {
    artwork: ArtworkInfo
    contractOracle: ContractOracle
    info: ExhibitionItemInfo
}

const ExhibitionItem: FunctionComponent<ExhibitionItemProps> = (props) => {

    return (
        <div className="item">
            <Link to={`/artworks/${props.info.artworkId}`} >
                <div className="title-and-artist">
                    <div className="title">{props.artwork.title}</div>
                    <Avatar width={26} style={{position: "relative", top: "6px"}} address={props.artwork.artist_address} />
                    <span className="artist">by {props.artwork.artist}</span>
                    <div className="mint-price">edition of {props.artwork.max_mint_count.toString()} for {props.artwork.price_to_mint / 1000000} tez</div>
                </div>
            </Link>
            <div className="statements">
                <h3>Curator's statement</h3>
                <p>{props.info.curatorStatement}</p>
                <h3>Artist's bio</h3>
                <p>{props.info.artistBio}</p>
            </div>
            <Link className="mint-thumbnail-link" to={`/artworks/${props.info.artworkId}`} >
                <div className="mint-thumbnail-container">
                    <MintThumbnail contractOracle={props.contractOracle} artwork={props.artwork} mintNumber={new BigNumber(1)} doGradientOverlay={false} />
                </div>
            </Link>
        </div>
    )

}


export default Exhibition;
