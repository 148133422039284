import { FunctionComponent, useState } from 'react';
import ArtworkInfo from '../engine/endlessways-common-js/ArtworkInfo';
import { BeaconWallet } from '@taquito/beacon-wallet';
import { BeaconError } from '@airgap/beacon-sdk';
import { useEffect } from 'react';
import { useUserAddressContext } from '../engine/UserAddressContext';
import { ReactComponent as ReloadSVG } from '../assets/reload.svg';
import ContractOracle from '../engine/endlessways-common-js/ContractOracle';
import { useHistory } from 'react-router';
import { ConfirmMint } from './ConfirmMint';


interface ArtworkMintUIProps {
    artwork: ArtworkInfo,
    wallet?: BeaconWallet,
    contractOracle: ContractOracle,
    seed?: string,
    makeNewSeed: () => string,
    setShowMintPendingAnimation: (show: boolean) => void,
    refreshMintCountCallback: (artworkId: number) => Promise<ArtworkInfo | undefined>,
    setMintState?: (state: ArtworkMintState) => void,
}

export enum ArtworkMintState {
    Idle = 0,
    Requesting = 1,
    AwaitingConfirmation = 2
}

export const ArtworkMintUI: FunctionComponent<ArtworkMintUIProps> = (props) => {

    type StateEnum = ArtworkMintState;
    const State = ArtworkMintState;

    const [mintingState, setMintingState] = useState<StateEnum>(State.Idle);
    const [error, setError] = useState<string>("");
    const [userHasClickedStart, setUserHasClickedStart] = useState<boolean>(false);
    const [confirmationsReceived, setConfirmationsReceived] = useState<number>(0);
    const [userIsArtist, setUserIsArtist] = useState<boolean>(false);
    const [confirmMintModalVisible, setConfirmMintModalVisible] = useState<boolean>(false);

    const kConfirmationsToWaitFor = 1;

    const userAddress = useUserAddressContext();

    useEffect(() => {
        (async () => {
            if (props.wallet) {
                setUserIsArtist((props.artwork.artist_address === userAddress));
            }
        })();
    }, [props.wallet, props.artwork, userAddress]);

    useEffect(() => {
        if (props.setMintState) {
            props.setMintState(mintingState);
        }
    }, [mintingState, props]);

    const history = useHistory();

    function confirmMint() {
        setConfirmMintModalVisible(true)
    }

    async function doMint() {

        const handleError = (error: any) => {
            console.error("Error during mint:", error);
            setMintingState(State.Idle);
            setUserHasClickedStart(false);
            props.setShowMintPendingAnimation(false);
            if (error instanceof BeaconError) {
                let beaconError = error as BeaconError;
                if (beaconError.name === "UnknownBeaconError" && beaconError.title === "Aborted") {
                    // aborted by the user
                } else {
                    let contractErrorCodeRegex = /(EWY_|FA2_)[A-Z_]+/;
                    const matches = contractErrorCodeRegex.exec(beaconError.fullDescription.description);
                    if (matches) {
                        let ewyErrorCode = matches[0];
                        if (ewyErrorCode === "EWY_DUPLICATE_SEED") {
                            setError("Error: This seed has already been minted.");
                        } else {
                            setError("Error: " + ewyErrorCode);
                        }
                    } else {
                        setError("Error doing transaction, check log for more details");
                        console.error("Error doing transaction:", beaconError);
                    }
                }
            } else if (error instanceof Error) {
                if (error.message.startsWith("Taquito missed a block while waiting for operation confirmation")) {
                    setError("Unable to confirm mint. Please refresh and/or check your wallet.");
                } else {
                    setError((error as Error).message);
                }
            } else {
                setError("Error doing transaction, check log for more details");
            }
        }

        try {
            if (props.wallet) {
                setError("");
                setMintingState(State.Requesting);
                const contract = (await props.contractOracle.getContractDirectAccess(props.artwork.id))?.contract;
                if (!contract) {
                    setError("Couldn't get contract");
                    setMintingState(State.Idle);
                    return;
                }
                setConfirmationsReceived(0);
                // mint!
                //let op = await props.contractOracle.mint(props.artwork, props.wallet);
                //console.log("going to mint artwork", props.artwork.id, "seed", props.seed, "with an amount of", props.artwork.price_to_mint, "mutez");
                
                // there's a loop in the mint transaction to check for a duplicate seed. this takes more and more gas with each new mint. 
                // so we need to estimate that. 
                // a limit of 43 per mint seems to work up until mint #64 where it suddenly spikes for some reason to 57. 
                // so ok fine let's use 57, but let's also make it a bit more, say 43+8 = 61
                
                // it would be great to use Taquito for this, but there's no way to request a wallet to sign a transaction.

                //const feePerMint = (props.artwork.mint_count.lt(64) ? 43 : 61);
                let fee = 5145 + 423 * props.artwork.mint_count.toNumber();
                let gasLimit = fee * 5;
                let storageLimit = 1000;
                let op = await contract.methods.mint_and_purchase(props.artwork.id, props.seed).send(
                    { amount: props.artwork.price_to_mint, 
                        mutez: true,
                        fee: fee,
                        gasLimit: gasLimit,
                    storageLimit: storageLimit })
                //console.log("mint produced operation", op);
                props.setShowMintPendingAnimation(true);

                setMintingState(State.AwaitingConfirmation);
                //startMintTimer();
                // wait for confirmation...
                console.log("waiting for confirmation...");
                let confirmationObservable = op.confirmationObservable(kConfirmationsToWaitFor);
                confirmationObservable.subscribe((data) => {
                    console.log("confirmation observer call with data", data);
                    const level = data.block.header.level;
                    //console.log("operation found in level %d", level);
                    if (data.currentConfirmation > 0) {
                        setConfirmationsReceived(data.currentConfirmation);
                    }
                    if (data.completed) {
                        (async () => {
                            console.log("confirmation completed with data", data, "isInCurrentBranch returning", await data.isInCurrentBranch());
                            const status = await op.status();
                            const wasApplied = (status === 'applied');
                            if (!wasApplied) {
                                const results = await op.operationResults();
                                console.error("Mint was not applied. Operation status: '", status, "', hash:", data.block.hash, "results:", results, "data:", data);
                                switch (status) {
                                    case 'failed':
                                        setError("Mint failed. Please check console logs for details.");
                                        break;
                                    case 'pending':
                                        setError("Mint is still pending after waiting for " + kConfirmationsToWaitFor + " blocks. Please check console logs for details.");
                                        break;
                                    case 'skipped':
                                        setError("Mint was skipped. Please check console logs for details.");
                                        break;
                                    case 'backtracked':
                                        setError("Mint was backtracked. Please check console logs for details.");
                                        break;
                                    case 'unknown':
                                    default:
                                        setError("Mint operation state: '" + status + "'. Please check console logs for details.");
                                        break;
                                }
                                setMintingState(State.Idle);
                                setUserHasClickedStart(false);
                                props.setShowMintPendingAnimation(false);
                            } else {
                                const waitForIndexer = async () => {
                                    //console.log("checking indexer for level %d", level);

                                    // if the indexer is down, then we don't need to wait for it because we will pull the data from the tezos node directly
                                    const hasIndexed = (await props.contractOracle.getIndexerIsUp() ? await props.contractOracle.getIndexerHasIndexedLevel(level) : true)
                                    if (hasIndexed) {
                                        //console.log("indexer has indexed level %d", level);
                                        //console.log("-> Calling refresh artworks");
                                        const oldTokenOwners = props.artwork.token_owners ?? [];
                                        const refreshedArtwork = await props.refreshMintCountCallback(props.artwork.id);
                                        setMintingState(State.Idle);
                                        setUserHasClickedStart(false);
                                        props.setShowMintPendingAnimation(false);
                                        
                                        const fallbackLocation = `/collection/${userAddress}`;
                                        if (!refreshedArtwork) {
                                            // fallback: go to collection page
                                            history.push(fallbackLocation);
                                        } else {
                                            // jump to mint, if possible
                                            const newTokenOwners = refreshedArtwork.token_owners ?? [];
                                            const addedTokenOwners = Object.entries(newTokenOwners).filter(([tokenId, owner]) => {
                                                return !(oldTokenOwners[tokenId]);
                                            });
                                            //console.log("added token owners:", addedTokenOwners);
                                            const currentUsersToken = addedTokenOwners.filter((e) => {
                                                return (e['1'] as string) === userAddress;
                                            })[0];
                                            // if more than one person bought something, go to the big mint view
                                            if (currentUsersToken) {
                                                if (addedTokenOwners.length > 1) {
                                                    const mintedTokenId = currentUsersToken['0'];
                                                    //console.log("found", mintedTokenId);
                                                    history.push(`/mint/${mintedTokenId}`);
                                                } else {
                                                    // reload the artwork - user's mint is on top
                                                    history.push({ pathname: `/artworks/${props.artwork.id}` });
                                                }
                                            } else {
                                                //console.log("couldn't find token, just going to collection");
                                                history.push(fallbackLocation);
                                            }
                                        }
                                        //history.push({ pathname: `/artworks/${props.artwork.id}`});
                                        //history.push(`/collection/${userAddress}`);

                                    } else {
                                        //console.log("waiting for indexer to index level %d", level);
                                        setTimeout(waitForIndexer, 1000);
                                    }
                                }
                                waitForIndexer();
                            }
                        })();
                    }
                }, (error) => {
                    handleError(error);
                });
                console.log("mint trigger completed, waiting for confirmation callback");
            }
        } catch (error) {
            handleError(error);
        }
    }

    const isMintedOut = props.artwork.mint_count.isGreaterThanOrEqualTo(props.artwork.max_mint_count);
    const noWallet = (!props.wallet);
    const tezosPrice = (props.artwork.price_to_mint / 1000000).toString();
    const pausedAndNotArtist = props.artwork.is_paused && !userIsArtist;
    const pausedAndArtist = props.artwork.is_paused && userIsArtist;
    const mintButtonText = (isMintedOut ? "sold out" : (pausedAndNotArtist ? "inactive" : (noWallet ? "wallet disconnected" : ("mint for " + tezosPrice + "ꜩ" + (pausedAndArtist ? " (🔒)" : "")))));
    const clickToStartButtonText = (isMintedOut ? "sold out" : (pausedAndNotArtist ? "inactive" : ("click to start" + (pausedAndArtist ? " (🔒)" : ""))));

    const clickToStartDisabled = (isMintedOut || pausedAndNotArtist);
    const mintingDisabled = (noWallet || isMintedOut || pausedAndNotArtist);

    const mintButton = (
        <button className="mint-button"
            disabled={(mintingState !== State.Idle) || mintingDisabled}
            onClick={confirmMint}>
            {mintingState === State.Idle ? (
                <span>{mintButtonText}</span>
            ) : (
                <span>
                    <i className="fas fa-spinner fa-spin"></i>&nbsp; {mintingState === State.Requesting ? "requesting..." :
                        /*`minting (${mintPercentString}%)...`*/
                        `minting (${Math.round(100 * (confirmationsReceived + 1) / (kConfirmationsToWaitFor + 1))}%)...`

                    }
                </span>
            )}
        </button>);

    //console.log('props.seed', props.seed);

    return (
        <div className="mint-ui" >
            <div className="mint-buttons">
                {(props.seed || userHasClickedStart) ? (
                    <>
                        {props.artwork.selectSeedOnMint &&
                            <button className="reload-button"
                                disabled={(mintingState !== State.Idle) || clickToStartDisabled}
                                onClick={(e) => { props.makeNewSeed() }} >
                                <span><ReloadSVG style={{ width: "17px" }} /></span>
                            </button>
                        }
                        {mintButton}
                    </>
                ) : (
                    <button className="preview-start-button"
                        disabled={(mintingState !== State.Idle) || clickToStartDisabled}
                        onClick={(e) => {
                            setUserHasClickedStart(true);
                            if (props.artwork.selectSeedOnMint) {
                                props.makeNewSeed()
                            } else {
                                props.setShowMintPendingAnimation(true)
                            }
                        }} >
                        <span>{clickToStartButtonText}</span>
                    </button>
                )
                }
            </div>
            <div className="error">{error}</div>
            <ConfirmMint
                isOpen={confirmMintModalVisible}
                artworkId={props.artwork.id}
                artworkTitle={props.artwork.title}
                artworkArtist={props.artwork.artist}
                walletAddress={userAddress ?? ""}
                seed={props.seed}
                acceptCallback={() => {
                    setConfirmMintModalVisible(false);
                    doMint();
                }}
                cancelCallback={() => setConfirmMintModalVisible(false)}
            />
        </div>

    );
}

export default ArtworkMintUI;