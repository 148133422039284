import AddArtwork from "./AddArtwork";
import Artworks from "./Artworks";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
} from "react-router-dom";
import { BeaconWallet } from "@taquito/beacon-wallet";
import ManageEndlessWaysContract from "./ManageEndlessWaysContract";
import FourOhFour from "./FourOhFour";
import ContractOracle from "../engine/endlessways-common-js/ContractOracle";
import { useEffect, useState } from 'react';
import { useUserAddressContext } from "../engine/UserAddressContext";
import "./Admin.css";

type AdminProps = {
    contractOracle: ContractOracle,
    wallet: BeaconWallet
};

function Admin(props: AdminProps) {
    let match = useRouteMatch();

    let userAddress = useUserAddressContext();

    //console.log("rendering admin");

    const [isAdmin, setIsAdmin] = useState<boolean>();

    useEffect(() => {
        (async () => {
            //console.log("updating active contract");
            const activeContract = props.contractOracle.getActiveContractDirectAccess();
            //console.log("got active contract", activeContract, "now updating admin address");
            const adminAddress = await activeContract?.getAdminWalletAddress();
            const isAdmin = (adminAddress === userAddress);
            setIsAdmin(isAdmin);
            //console.log("got admin address", adminAddress, "active address is", userAddress, "same?", isAdmin);
        })()
    }, [props.contractOracle, userAddress]);

    useEffect(() => {
        isAdmin && (document.title = `Endless Ways: admin`);
    }, [isAdmin]);

    //console.log('rendering with isAdmin', isAdmin);

    return (
        <div>
            {isAdmin ? (
                <Switch>
                    <Route path={`${match.path}/addArtwork`}>
                        <AddArtwork contractOracle={props.contractOracle} wallet={props.wallet} />
                    </Route>
                    <Route path={`${match.path}/manageContract/:contractAddress(KT[0-9a-zA-Z]{34})?`}>
                        <ManageEndlessWaysContract contractOracle={props.contractOracle}/>
                    </Route>
                    <Route path={match.path} exact={true}>
                        <h1>Admin</h1>
                        <p>Pick a thing to do:</p>
                        <ul>
                            <li><Link to={`${match.url}/manageContract`}>Manage the EndlessWays smart contract</Link></li>
                            <li><Link to={`${match.url}/addArtwork`}>Add an artwork</Link></li>
                            <li>Edit an artwork:
                                <div><Artworks contractOracle={props.contractOracle} wallet={props.wallet} forAdminPage={true} showArtistAddress={true} routeMatchUrl="/artworks" /></div>
                            </li>
                        </ul>
                    </Route>
                    <Route path="*">
                        <FourOhFour />
                    </Route>
                </Switch>
            ) : (
                <FourOhFour />
            )}
        </div>

    );
}


export default Admin;
