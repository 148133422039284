import { WalletContract } from '@taquito/taquito';
import { FunctionComponent, useEffect, useState } from 'react';
import { BeaconError } from '@airgap/beacon-sdk';
import BigNumber from 'bignumber.js';
import { useUserAddressContext } from '../engine/UserAddressContext';
import ContractOracle from '../engine/endlessways-common-js/ContractOracle';
import ContractDirectAccess from '../engine/endlessways-common-js/ContractDirectAccess';

import { compose } from '@taquito/taquito';
import { tzip12 } from '@taquito/tzip12';
import { tzip16 } from '@taquito/tzip16';


interface ManageEndlessWaysContractProps {
    contractOracle: ContractOracle
}

const ManageEndlessWaysContract: FunctionComponent<ManageEndlessWaysContractProps> = (props) => {

    const [administratorAddress, setAdministratorAddress] = useState<string>("");
    const [baseURIs, setBaseURIs] = useState({images: "", live: "", external: ""});
    const [defaultBaseMetadataURI, setDefaultBaseMetadataURI] = useState<string>("");
    const [paused, setPaused] = useState<boolean>(false);
    const [royaltyWalletAddress, setRoyaltyWalletAddress] = useState<string>("");
    const [royaltyPercent, setRoyaltyPercent] = useState<number>(0);
    const [tokenIdToDump, setTokenIdToDump] = useState<BigNumber | undefined>();

    const [isChangingPaused, setIsChangingPaused] = useState<boolean>(false);
    const [isPopulating, setIsPopulating] = useState<boolean>(false);
    const [isSettingBaseURIs, setIsSettingBaseURIs] = useState<boolean>(false);
    const [isSettingDefaultBaseMetadataURI, setIsSettingDefaultBaseMetadataURI] = useState<boolean>(false);
    const [isSettingPlatformRoyaltyInfo, setIsSettingPlatformRoyaltyInfo] = useState<boolean>(false);
    const [contract, setContract] = useState<WalletContract | undefined>();

    const [error, setError] = useState<string>("");

    useEffect(() => {
        (async () => {
            const cda = props.contractOracle.getActiveContractDirectAccess();
            setContract(cda?.contract);
        })()
    }, [props.contractOracle]);

    async function updateStorage() {
        const storage: any = await props.contractOracle.getActiveStorage();
        setAdministratorAddress(storage.administrator);
        setPaused(storage.paused);
        setRoyaltyWalletAddress(storage.platform_royalty_wallet);
        const royaltyShare = storage.platform_royalty_pct_3dp.toNumber();
        const royaltyPct = 100*(royaltyShare/1000);
        setRoyaltyPercent(royaltyPct);
        setBaseURIs({
            images: ContractOracle.hexToUtf8String(await storage.metadata.get("base_images_uri") ?? "none"),
            live: ContractOracle.hexToUtf8String(await storage.metadata.get("base_live_uri") ?? "none"),
            external: ContractOracle.hexToUtf8String(await storage.metadata.get("base_external_uri") ?? "none")
        })
        setDefaultBaseMetadataURI(ContractOracle.hexToUtf8String(await storage.metadata.get("default_base_metadata_uri") ?? "none"))
    }

    useEffect(() => {
        updateStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract]);

    function handleError(error: any) {
        console.error(error);
        if (error instanceof BeaconError) {
            let beaconError = error as BeaconError;
            setError(beaconError.fullDescription.description);
        } else if (error instanceof Error) {
            setError((error as Error).message);
        } else {
            setError("Error doing transaction, check log for more details");
        }
    }

    const toggleContractPaused = async (): Promise<void> => {
        if (!contract) {
            return;
        }
        setIsChangingPaused(true);
        try {
            const op = await contract.methods.set_pause(!paused).send();
            await op.confirmation();
            await updateStorage();
        } catch (error) {
            handleError(error);
        } finally {
            setIsChangingPaused(false);
        }
    }

    const kShowTokenSeedScript = `
    function unHash(hash) {
        let numbers = [];
        let count = 10;
        let step = 6; // ten numbers
        //print("steppy: " + step + " -> range: " + range);
        for (let j = 0; j < count; j++) {
          let partOfHash = hash.slice(j * step, (j + 1) * step);
          let number = parseInt(partOfHash, 16);
          numbers.push(number / 16777215);
        }
        return numbers; // return 10 numbers, 0..1
      }
      
      
      function setup() {
        createCanvas(windowWidth, windowHeight);
        
        const numbers = unHash(endlessWaysTokenInfo.seed);
        
        colorMode(HSB);
        background(color(numbers[0]*360,numbers[1]*140+60,numbers[2]*128+128));
        fill(255);
        //console.log("about to draw");
        const yOffset = numbers[3]*100
        text(endlessWaysTokenInfo.artworkId, 10+numbers[4]*50, 20+yOffset);
        text(endlessWaysTokenInfo.mintNumber, 10+numbers[5]*50, 35+yOffset);
        text(endlessWaysTokenInfo.seed, 10+numbers[6]*50, 50+yOffset);
        //console.log("drawn");
        noLoop();
      }
      
      
      function draw() {
      
      }`;


    const userAddress = useUserAddressContext();
    async function addTestArtwork(script: string) {
        if (!contract) {
            return;
        }
        setIsPopulating(true);
        try {
            var artistAddress = userAddress;
            var storage:any = await props.contractOracle.getActiveStorage();
            const newArtworkId = (storage.next_artwork_id as BigNumber).toNumber();
            console.log(`populating ${newArtworkId} for artist ${artistAddress}`)
            var batch = props.contractOracle.tezosToolkit.wallet.batch();
            batch = batch.withContractCall(contract.methods.add_artwork(artistAddress));
            batch = batch.withContractCall(contract.methods.set_artwork_title(newArtworkId, ContractOracle.utf8StringToHex("artwork sample")));
            batch = batch.withContractCall(contract.methods.set_artwork_artist(newArtworkId, ContractOracle.utf8StringToHex("@__ohne is not an artist"), ContractOracle.utf8StringToHex(`[ "${artistAddress}" ]`)));
            batch = batch.withContractCall(contract.methods.set_artwork_paused(newArtworkId, false));
            batch = batch.withContractCall(contract.methods.set_artwork_description(newArtworkId, ContractOracle.utf8StringToHex("A sample artwork that prints hash on a coloured background.")));
            batch = batch.withContractCall(contract.methods.set_artwork_script(newArtworkId, ContractOracle.utf8StringToHex(kShowTokenSeedScript)));

            const op = await batch.send();
            await op.confirmation();
            await updateStorage();
        } catch (error) {
            handleError(error);
        } finally {
            setIsPopulating(false);
        }

    }

    async function storeBaseURIs() {
        if (!contract) {
            return;
        }
        setIsSettingBaseURIs(true);
        try {
            console.log("storing base URIs:", baseURIs.images, baseURIs.live, baseURIs.external);
            const method = contract.methods.set_base_uris(baseURIs.images, baseURIs.live, baseURIs.external);
            const op = await method.send();
            await op.confirmation();
            await updateStorage();
        } catch (error) {
            handleError(error);
        } finally {
            setIsSettingBaseURIs(false);
        }
    }

    async function storeDefaultBaseMetadataURI() {
        if (!contract) {
            return;
        }
        setIsSettingDefaultBaseMetadataURI(true);
        try {
            const defaultBaseMetadataURIHex = ContractOracle.utf8StringToHex(defaultBaseMetadataURI);
            const method = contract.methods.set_default_base_metadata_uri(defaultBaseMetadataURIHex);
            const op = await method.send();
            await op.confirmation();
            await updateStorage();
        } catch (error) {
            handleError(error);
        } finally {
            setIsSettingDefaultBaseMetadataURI(false);
        }

    }

    async function setPlatformRoyaltyInfo() {
        if (!contract) {
            return;
        }
        setIsSettingPlatformRoyaltyInfo(true);
        try {
            var batch = props.contractOracle.tezosToolkit.wallet.batch();
            batch = batch.withContractCall(contract.methods.set_platform_royalty_wallet(royaltyWalletAddress));
            const royaltyShare3dp = Math.min(1000, Math.max(0, Math.floor((royaltyPercent/100)*1000)));
            batch = batch.withContractCall(contract.methods.set_platform_royalty_pct_3dp(royaltyShare3dp));
            const op = await batch.send();
            await op.confirmation();
            await updateStorage();
        } catch (error) {
            handleError(error);
        } finally {
            setIsSettingPlatformRoyaltyInfo(false);
        }
    }

    async function dumpContractInfo(): Promise<void> {
        const contractAddresses = props.contractOracle.contractDirectAccess.map((cda: ContractDirectAccess) => cda.contractAddress );
        contractAddresses.forEach( async (contractAddress) => {
            const script = await props.contractOracle.tezosToolkit.rpc.getNormalizedScript(contractAddress);
            const entrypoints = await props.contractOracle.tezosToolkit.rpc.getEntrypoints(contractAddress);
            console.log("'" + contractAddress + "'': ", JSON.stringify({ script: script, entrypoints: entrypoints}));
        })
    }

    async function dumpTokenMetadata(tokenIdBN: BigNumber): Promise<void> {
        try {
            const tokenId = tokenIdBN.toNumber();

            const Tezos = props.contractOracle.tezosToolkit;

            const contractAddress = 'KT1VdCrmZsQfuYgbQsezAHT1pXvs6zKF8xHB';
            const contract = await Tezos.contract.at(contractAddress, compose(tzip16, tzip12));
            if (!contract) {
                throw new Error("Can't get contract");
            }
            const metadata = await contract.tzip12().getTokenMetadata(tokenId);
            console.log("for token id", tokenId.toString(), "got metadata", metadata);
        } catch (err) {
            console.error("Couldn't get metadata:", err);
        }
    }

    return (
        <div className="manage-contract">
            <h1>Manage contract {contract?.address ?? "no address"}</h1>
            <div>
                <label>Administator wallet address (don't change this unless you know what you're doing)</label>
                <input
                    size={50}
                    type="text"
                    disabled={true}
                    value={administratorAddress}
                />
            </div>
            <div>Paused? {paused ? "Yes" : "No"} &nbsp;
                <button className="button" disabled={isChangingPaused || (!contract)}
                    onClick={toggleContractPaused}>
                    {isChangingPaused ? (
                        <span>
                            <i className="fas fa-spinner fa-spin"></i>&nbsp; Storing...
                        </span>
                    ) : (
                        <span>
                            <i className="fas fa-play"></i>&nbsp; {paused ? "Unpause" : "Pause"}
                        </span>
                    )}
                </button>
            </div>
            <div className="add-test-artwork">
                <button className="button" disabled={isPopulating || (!contract)}
                    onClick={() => addTestArtwork(kShowTokenSeedScript)}>
                    {isPopulating ? (
                        <span>
                            <i className="fas fa-spinner fa-spin"></i>&nbsp; Adding...
                        </span>
                    ) : (
                        <span>
                            <i className="fas fa-bolt"></i>&nbsp; Add test artwork
                        </span>
                    )}
                </button>
            </div>
            <div className="base-uris">
                <label>Base thumbnail URI</label>
                <input
                    size={50}
                    type="text"
                    disabled={isSettingBaseURIs}
                    defaultValue={baseURIs.images}
                    onChange={e => { setBaseURIs({images: e.target.value, live: baseURIs.live, external: baseURIs.external})}}
                />
                <label>Base live URI (raw mint HTML)</label>
                <input
                    size={50}
                    type="text"
                    disabled={isSettingBaseURIs}
                    defaultValue={baseURIs.live}
                    onChange={e => { setBaseURIs({images: baseURIs.images, live: e.target.value, external: baseURIs.external})}}
                />
                <label>Base "external" URI (TZIP-21: URI with "additional information about the subject or content of the asset")</label>
                <input
                    size={50}
                    type="text"
                    disabled={isSettingBaseURIs}
                    defaultValue={baseURIs.external}
                    onChange={e => { setBaseURIs({images: baseURIs.images, live: baseURIs.live, external: e.target.value})}}
                />
                <button className="button" disabled={isSettingBaseURIs || (!contract)}
                    onClick={storeBaseURIs}>
                    {isSettingBaseURIs ? (
                        <span>
                            <i className="fas fa-spinner fa-spin"></i>&nbsp; Setting base URIs...
                        </span>
                    ) : (
                        <span>
                            <i className="fas fa-network-wired"></i>&nbsp; Set base URIs
                        </span>
                    )}
                </button>
                <label>Default base token_metadata URI (can be overridden on a per-artwork basis via the extra_data key 'baseMetadataUri')</label>
                <input
                    size={50}
                    type="text"
                    disabled={isSettingDefaultBaseMetadataURI}
                    defaultValue={defaultBaseMetadataURI}
                    onChange={e => { setDefaultBaseMetadataURI(e.target.value) }}
                />
                <button className="button" disabled={isSettingDefaultBaseMetadataURI || (!contract)}
                    onClick={storeDefaultBaseMetadataURI}>
                    {isSettingDefaultBaseMetadataURI ? (
                        <span>
                            <i className="fas fa-spinner fa-spin"></i>&nbsp; Setting default base token_metadata URI...
                        </span>
                    ) : (
                        <span>
                            <i className="fas fa-network-wired"></i>&nbsp; Set default base token_metadata URI
                        </span>
                    )}
                </button>
            </div>
            <div className="platform-cut">
                <label>Platform royalty wallet address</label>
                <input type="text" 
                    disabled={isSettingPlatformRoyaltyInfo}
                    defaultValue={royaltyWalletAddress}
                    onChange={e => setRoyaltyWalletAddress(e.target.value)}
                               />
                <label>Platform royalty cut percent</label>
                <input type="number" 
                    disabled={isSettingPlatformRoyaltyInfo}
                    value={royaltyPercent}
                    min={0}
                    max={100}
                    onChange={e => setRoyaltyPercent(parseFloat(e.target.value))}
                               />
                <button className="button" disabled={isSettingPlatformRoyaltyInfo || (!contract)}
                    onClick={setPlatformRoyaltyInfo}>
                    {isSettingPlatformRoyaltyInfo ? (
                        <span>
                            <i className="fas fa-spinner fa-spin"></i>&nbsp; Setting royalty info...
                        </span>
                    ) : (
                        <span>
                            <i className="far fa-handshake"></i>&nbsp; Set royalty info
                        </span>
                    )}
                </button>
            </div>

            <div>

                <button className="button" onClick={dumpContractInfo}>
                    <span>Dump contract infos to js console</span>
                </button> 

                <input type="number" 
                    min={0}
                    max={100}
                    onChange={e => setTokenIdToDump(new BigNumber(e.target.value))}
                               />
                <button className="button" disabled={!tokenIdToDump} onClick={(e) => dumpTokenMetadata(tokenIdToDump!)}>
                    <span>Dump token metadata</span>
                </button> 

            </div>

            <div>{error}</div>
        </div>
    );
}

export default ManageEndlessWaysContract;