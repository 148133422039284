import { BeaconWallet } from "@taquito/beacon-wallet";
import {
    NetworkType,
} from "@airgap/beacon-sdk";
import { Dispatch, SetStateAction, useState } from 'react';
import { useUserAddressContext } from '../engine/UserAddressContext';
import { useEffect } from 'react';
import { ReactComponent as WalletPopupBottomSVG } from '../assets/walletPopupBottom.svg';
import { ReactComponent as WalletPopupTopSVG } from '../assets/walletPopupTop.svg';
import { Link } from 'react-router-dom';
import ContractOracle from '../engine/endlessways-common-js/ContractOracle';
import OutsideAlerter from "./OutsideAlerter";
import AddressOrTzktAlias from './AddressOrTzktAlias';
import Avatar from "./Avatar";


type LoginOrDisconnectProps = {
    contractOracle: ContractOracle,
    beaconWallet?: BeaconWallet,
    setBeaconWallet: Dispatch<SetStateAction<BeaconWallet | undefined>>,
    preferredNetwork: NetworkType,
    rpcUrl: string
};

function LoginOrDisconnect(props: LoginOrDisconnectProps) {

    const [isConnectingWallet, setIsConnectingWallet] = useState<boolean>(false);
    const contextualUserAddress = useUserAddressContext();
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    function toggleMenuOpen() {
        setMenuIsOpen(!menuIsOpen);
    }

    function makeNewBeaconWallet() {
        var beaconWallet = new BeaconWallet({
            name: "Endless Ways",
            preferredNetwork: props.preferredNetwork,
            /*disableDefaultEvents: true, // Disable all events / UI. This also disables the pairing alert.
            eventHandlers: {
                // To keep the pairing alert, we have to add the following default event handlers back
                [BeaconEvent.PAIR_INIT]: {
                    handler: defaultEventCallbacks.PAIR_INIT
                },
                [BeaconEvent.PAIR_SUCCESS]: {
                    handler: data => console.log("missing setPublicToken")
                },
                [BeaconEvent.HIDE_UI]: {
                    handler: data => { console.log("BeaconEvent.HIDE_UI event"); console.log(data);}
                }, 
                [BeaconEvent.CHANNEL_CLOSED]: {
                    handler: data => { console.log("BeaconEvent.CHANNEL_CLOSED event"); console.log(data);}
                }
            }*/
        })
        //console.log("made beacon wallet with preferred network " + props.preferredNetwork);
        props.contractOracle.tezosToolkit.setWalletProvider(beaconWallet);
        return beaconWallet;
    }

    async function reconnectWallet() {
        try {
            const beaconWallet = makeNewBeaconWallet();
            const activeAccount = await beaconWallet.client.getActiveAccount();
            if (activeAccount) {
                //console.log("already connected as " + activeAccount.address);
                props.setBeaconWallet(beaconWallet);
            }
        } catch (error) {
            console.error("Error reconnecting wallet:", error);
        }
    }

    async function connectWallet() {
        var existingWallet = props.beaconWallet;
        if (existingWallet) {
            console.error("already have a wallet - call disconnect first");
            return;
        }
        try {
            setIsConnectingWallet(true);
            const beaconWallet = makeNewBeaconWallet();
            await beaconWallet.requestPermissions({
                network: { type: props.preferredNetwork, rpcUrl: props.rpcUrl }
            });
            setIsConnectingWallet(false);
            beaconWallet.client.getActiveAccount();
            props.setBeaconWallet(beaconWallet);
        } catch (error) {
            console.error("Error connecting wallet:", error);
        } finally {
            setIsConnectingWallet(false);
        }
    };

    const disconnectWallet = async (): Promise<void> => {
        try {
            await props.beaconWallet?.disconnect();
            await props.beaconWallet?.clearActiveAccount();
            props.setBeaconWallet(undefined);
        } catch (error) {
            console.error("Error disconnecting wallet:", error);
        }
    }

    // try to reconnect on first run
    useEffect(() => {
        reconnectWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const OuterNodeType = (menuIsOpen ? "div" : "button");

    return (
        props.beaconWallet ? (
            <div className="connect-wallet">
                <OuterNodeType className="looks-like-link login-or-disconnect" onClick={toggleMenuOpen}>
                    <span className="left-text">{contextualUserAddress && <AddressOrTzktAlias address={contextualUserAddress} />}</span>
                    <Avatar width={35} address={contextualUserAddress as string} />
                    <div className="wallet-dropdown">
                    { menuIsOpen && (
                        <div className="menu">
                            <OutsideAlerter callback={toggleMenuOpen}>
                                <WalletPopupTopSVG  display="block"/>
                                <div className="body">
                                    <Link to={`/collection/${contextualUserAddress}`} className="text" onClick={toggleMenuOpen}>My Collection</Link>
                                    <div className="separator" />
                                    <button className="looks-like-link text" onClick={(e) => {disconnectWallet(); toggleMenuOpen();}}>Disconnect</button>
                                </div>
                                <WalletPopupBottomSVG display="block"/>
                            </OutsideAlerter>
                        </div>
                    ) }
                    </div>
                </OuterNodeType>
            </div>
        ) : (
            <div className="connect-wallet" onClick={(e) => connectWallet()}>
                {isConnectingWallet ? (
                    <span className="left-text">Connecting <i className="fas fa-spinner fa-spin" /></span>
                ) : ( 
                    <button className="looks-like-link.left-text">Connect</button>
                )}
                <img className="wallet-icon" src={"/images/wallet.svg"} alt='wallet icon' />
            </div>
        )
    );
}

export default LoginOrDisconnect;