import { BeaconWallet } from "@taquito/beacon-wallet";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArtworkInfo from "../engine/endlessways-common-js/ArtworkInfo";
import ContractOracle from "../engine/endlessways-common-js/ContractOracle";
import Avatar from "./Avatar";
import ArtworkMintProgress from "./ArtworkMintProgress";
import { MintThumbnail } from "./MintThumbnail";

export type ArtworkListProps = {
    contractOracle: ContractOracle,
    wallet?: BeaconWallet,
    forAdminPage: boolean,
    showArtistAddress?: boolean
}

export function ArtworkList(props: ArtworkListProps) {

    const [artworks, setArtworks] = useState<ArtworkInfo[]>();

    useEffect(() => {
        (async () => {
            let artworksArray = await props.contractOracle.getArtworks(1, 50);
            setArtworks(artworksArray);
        })();
    }, [props.contractOracle]);

    let allArtworks = (artworks ?? [])
    let artworksToShow = (props.forAdminPage ? 
        allArtworks : 
        allArtworks.filter((artwork) => artwork.isVisible));
    //console.log("all artworks", allArtworks);
    //console.log("artworks to show", artworksToShow);
    

    return (
        (artworksToShow.length > 0) ? (
            <div className="artworks" style={{justifyContent: (artworksToShow.length === 1) ? "center" : "left"}}>
                {artworksToShow.map((artwork, index) => 
                    <div key={index} className="artwork-card" >
                        <Link to={`/artworks/${artwork.id}`}>
                            <MintThumbnail contractOracle={props.contractOracle} artwork={artwork} mintNumber={artwork.mint_count} doGradientOverlay={true} />
                            <Avatar address={artwork.artist_address} />
                            <div className="artwork-details">
                                <div>
                                    <div className="title">{artwork.title}</div>
                                    <div className="artist">by {artwork.artist}</div>
                                    {props.showArtistAddress && <div className="artist">{artwork.artist_address}</div>}
                                </div>
                                <ArtworkMintProgress mintCount={artwork.mint_count} maxMintCount={artwork.max_mint_count} />
                            </div>
                        </Link>
                    </div> 
                )}
            </div>
        ) : ( artworks ? /* nothing visible */ <></> : /* still loading */ <h1 className="fade-in-out-2s">Loading data from contract...</h1> )
    );
}

export default ArtworkList;