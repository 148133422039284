import { BeaconWallet } from "@taquito/beacon-wallet";
import { useEffect, useState } from "react";
import { BeaconError } from "@airgap/beacon-sdk";
import ContractOracle from "../engine/endlessways-common-js/ContractOracle";
import { WalletContract } from "@taquito/taquito";

type AddArtworkProps = {
    contractOracle: ContractOracle,
    wallet: BeaconWallet | null
}

function AddArtwork(props: AddArtworkProps) {

    const [isAddingArtwork, setIsAddingArtwork] = useState<boolean>(false);
    const [artistAddress, setArtistAddress] = useState<string>("");
    const [artworkCount, setArtworkCount] = useState<number>(-1);
    const [error, setError] = useState<string>("")

    const updateArtworkCount = async (): Promise<void> => {
        //console.log("updating artwork count...");
        setArtworkCount(await props.contractOracle.getArtworkCount());
    }

    const [activeContract, setActiveContract] = useState<WalletContract | undefined>();
    useEffect(() => {
        (async () => {
            const activeCda = props.contractOracle.getActiveContractDirectAccess();
            setActiveContract(activeCda?.contract);
        })();
    }, [props.contractOracle, props.contractOracle.contractDirectAccess]);

    const addArtwork = async (): Promise<void> => {
        if (!activeContract) {
            return;
        }
        setIsAddingArtwork(true);
        try {
            const op = await activeContract.methods.add_artwork(artistAddress).send();
            await op.confirmation();
            await updateArtworkCount();
            setError("");
            setArtistAddress("");
        } catch (error) {
            console.error("Error doing transaction:", error);
            if (error instanceof BeaconError) {
                let beaconError = error as BeaconError;
                setError(beaconError.fullDescription.description);
            } else if (error instanceof Error) {
                setError((error as Error).message);
            } else {
                setError("Error doing transaction, check log for more details");
            }
        } finally {
            setIsAddingArtwork(false);
            updateArtworkCount();
        }
    };


    useEffect(() => {
        //console.log("AddArtwork useEffect about to setup");
        updateArtworkCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeContract]);


    return (
        <div className="add-artwork">
            <h1>Add an artwork</h1>
            <h3>Number of existing artworks: {artworkCount}</h3>
            <label>Artist wallet address</label>
            <input
                type="text"
                placeholder="Artist address"
                disabled={!activeContract}
                value={artistAddress}
                onChange={e => setArtistAddress(e.target.value)}
            />
            <button className="button" disabled={isAddingArtwork || (props.wallet === undefined)} onClick={addArtwork}>
                {isAddingArtwork ? (
                    <span>
                        <i className="fas fa-spinner fa-spin"></i>&nbsp; Adding...
                    </span>
                ) : (
                    <span>
                        <i className="fas fa-plus"></i>&nbsp; Add artwork
                    </span>
                )}
            </button>
            { (error.length > 0) && <div>{error}</div> }
        </div>
    )
}

export default AddArtwork;
