import { FunctionComponent } from 'react';

type MailingListSignupProps = {

}

export const MailingListSignup: FunctionComponent<MailingListSignupProps> = (props) => {

    const iframeSource = "https://97cbaf00.sibforms.com/serve/MUIEAFtf2yW-l4W7DuBJ1T-wfRsUf4fDkOPFe8-m15uk6Q77UPAyIpX3F5otcxP6uhJiKxKzgbdWXUhLNmyU8TQzZQ1Zv95js-2xIxdnBhrxDUf5dJ4G4Iivto28ir6ook2cZCnoYw68lwtBNQqFoeI2WPGKTJk_oDEAUACfdKS8gATj4sZ_BsC4axwzouZt0ly4pfIuh71FilI9";

    return (
        <iframe className="mailing-list-signup" title="Newsletter signup" src={iframeSource} 
                frameBorder="0" scrolling="auto" allowFullScreen ></iframe>
    )

}

